import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import {styled} from "@mui/material/styles";
import {base_url} from "../../../components/api_url/url";
import myStyled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Fragment, useEffect, useState} from "react";
import DividerLine from "../../../components/Divider/DividerLine"
import SelectData from "../../../components/Form/SelectForm";
import {Select} from "@mui/material";

const StyledContainer = styled(Container)`
  font-size: 20px;
  margin-top: 20px;
`



const ButtonWarpper = styled("div")`
  padding: 10px;
  text-align: center;
  margin-bottom: 15px;
`

const StyledLink = styled("div")`
  color: #FFFFFF;
  font-size: 17px;
  -webkit-text-decoration: none;
  width: 250px;
  text-decoration: none;
  background: #0096C7;
  border-radius: 29px;
  text-align: center;
  padding: 5px 20px 7px 15px;
  margin: auto;
  :hover {
    color: white;
    background: cadetblue;
  }
`

const StyledRow = styled(Row)`
  margin: 10px 15px;
`

const StyledIcon = myStyled(FontAwesomeIcon)`
  margin-right: 5px;
  text-weight: bold;
`

const statusList = [
  {value:0, label:'en attente'},
  {value:1, label:'en cours'},
  {value:2, label:'fini'},
  {value:3, label:'archivé'}
]

export default function Description({details, trigger, setTrigger}) {

  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [projectStatus, setProjectStatus] = useState("")
  const [editing, setEditing] = useState(false);
  const [entite, setEntite] = useState("");
  const [lat, setLat] = useState("");
  const [lon, setLon] = useState("");

  const [errorName, setErrorName] = useState(false);
  const [errorAddress, setErrorAddress] = useState(false);
  const [errorBegin, setErrorBegin] = useState(false);
  const [errorEntite, setErrorEntite] = useState(false);

  const validate = () => {
    setErrorName(false)
    setErrorAddress(false)
    setErrorBegin(false)
    setErrorEntite(false)
    let status = true
    if (!name) {
      status = false
      setErrorName(true)
    }
    if (!entite) {
      status = false
      setErrorEntite(true)
    }
    return status
  }

  const onEditClick = () => {
    if(editing){
      setEditing(false)
      // save the new data
      const address_data = {
        text: address,
        Lat: lat,
        Lon: lon
      }
      if (validate()) {
        fetch(base_url + 'project/address/' + details.address.id + "/", {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
          },
          body: JSON.stringify(address_data)
        })
        .then(res => {
          if (res.ok) {
            return res.json();
          }
          throw res;
        })
        .catch(error => {
          console.error("Error posting data Address: ", error);
          alert("il y a eu un problème dans l'envoie des donées");
        })
        .then(() => {
            const project_data = {
              id: details.id,
              name: name,
              address: details.address.id,
              entite: entite,
              status: projectStatus,
              begin: details.begin,
            }
            console.log(project_data);
            fetch(base_url + 'project/project/' + details.id + "/", {
              method: 'PUT',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
              },
              body: JSON.stringify(project_data)
            })
            .then(res => {
              if (res.ok) {
                return res.json();
              }
              throw res;
            })
            .then(() => {
              alert("les données du dossier ont bien été mis à jour");
              setTrigger(trigger+1);
            })
            .catch(error => {
              console.error("Error posting data Address: ", error);
              alert("il y a eu un problème dans l'envoie des donées");
            })
        })
      }
    } else {
      setEditing(true)
    }
  }

  useEffect( () => {
    setName(details.name);
    if(details.address) {
      if (details.address.text) {
        setAddress(details.address.text);
      }
      if (details.address.Lat) {
        setLat(details.address.Lat);
      }
      if (details.address.Lon) {
        setLon(details.address.Lon);
      }
    }
    setProjectStatus(details.status)
    setEntite(details.entite.id);
  }, [details]);

  return (
      <StyledContainer>
        <ButtonWarpper>
          {editing ?
              <StyledLink onClick={onEditClick}>
                <StyledIcon icon="fas fa-save"/>Enregister:
              </StyledLink> :
              <StyledLink onClick={onEditClick}>
                <StyledIcon icon="fas fa-edit"/>Modifier:
              </StyledLink>}
        </ButtonWarpper>
        <StyledRow>
          <Form.Group as={Col}>
            {editing ?
                <Fragment>
                  <Form.Label>Nom :</Form.Label>
                  <Form.Control
                      type="text"
                      value={name}
                      onChange={e => setName(e.target.value)}
                  />
                </Fragment> :
                <h5>Nom : {details.name}</h5>
            }
          </Form.Group>
          <Form.Group as={Col}>
            {editing ?
                <Fragment>
                  <Form.Label>Adresse :</Form.Label>
                  <Form.Control
                      type="text"
                      value={address}
                      onChange={e => setAddress(e.target.value)}
                  />
                </Fragment> :
                <h5>Adresse : {details.address ? details.address.text: "/"}</h5>
            }
          </Form.Group>
        </StyledRow>
       {/* à remettre pour la carte*/}
       {/* <StyledRow>
          <Form.Group as={Col}>
            {editing ?
                <Fragment>
                  <Form.Label>Lat :</Form.Label>
                  <Form.Control
                      type="text"
                      value={lat}
                      onChange={e => setLat(e.target.value)}
                  />
                </Fragment> :
                <h5>Lat : {details.address && details.address.Lat}</h5>
            }
          </Form.Group>
          <Form.Group as={Col}>
            {editing ?
                <Fragment>
                  <Form.Label>Lon :</Form.Label>
                  <Form.Control
                      type="text"
                      value={lon}
                      onChange={e => setLon(e.target.value)}
                  />
                </Fragment> :
                <h5>Lon : {details.address && details.address.Lon}</h5>
            }
          </Form.Group>
        </StyledRow>*/}
        <StyledRow>
          <Form.Group as={Col}>
            {editing ?
                <Fragment>
                  <Form.Label>Entite:</Form.Label>
                  <SelectData
                      setValue={setEntite}
                      value={entite}
                      defaultId={details.entite.id}
                      target="entite/"
                  />
                </Fragment> :
                <h5>Entité : {details.entite.name}</h5>
            }
          </Form.Group>
          <Form.Group as={Col}>
            {editing ?
              <Fragment>
                <Form.Label>statuts :</Form.Label>
                <Form.Select onChange={(e) => setProjectStatus(e.target.value)}>
                  {statusList.map(status => {
                    if(status.value === projectStatus){
                       return(
                        <option value={status.value} selected>{status.label}</option>
                      )
                    } else {
                      return(
                        <option value={status.value}>{status.label}</option>
                      )
                    }
                  }

                  )}
                </Form.Select>
              </Fragment> :
              <h5>statuts: {projectStatus !== "" && statusList[projectStatus].label}</h5>
            }
          </Form.Group>
        </StyledRow>
        <StyledRow>
          <Form.Group as={Col}>
                <h5>Crée le: {details.begin}</h5>
          </Form.Group>
        </StyledRow>
        <DividerLine/>
      </StyledContainer>
  );
}


import React, {useState} from "react";
import { scaleOrdinal } from "d3-scale";
import { schemeTableau10} from "d3-scale-chromatic";
import {base_url} from "../../../components/api_url/url";
import {
  ResponsiveContainer,
  PieChart,
  Pie,
  Sector,
  Legend,
  Cell, Tooltip,
} from "recharts";

const colors = scaleOrdinal(schemeTableau10).range();

function makeLegend(data, field){
  let legend = []
  for(let i=0; i<data.length; i++){
    legend.push({
      value: data[i][field],
      type: 'square',
      color:colors[i % colors.length],
      id: i
    });
  }
  return legend;
}

export default function MyPieChart({group, trigger, startDate, endDate}) {

  const [legend, setLegend] = useState([])
  const [dataRec, setDataRec] = useState([])


  React.useEffect(() => {
    let target = ""

    if(group) {

      let field;
      if(group === 'category'){
        target = 'personalHoursByCat/'
        field = 'category__name'
      }else if(group === 'project') {
        target = 'personalHoursByProject/'
        field = 'project__name'
      }

      let url = base_url + 'timesheet/'
      url += target

      let end_date;
      let start_date;
      if(startDate && endDate){
        start_date = new Date(startDate);
        end_date = new Date(endDate);
      } else {
        end_date = new Date();
        start_date = new Date();
        start_date.setDate(end_date.getDate() - 6);
      }

      url += "?end_date=" + end_date.toISOString().substr(0, 10);
      url += "&start_date=" + start_date.toISOString().substr(0, 10);
      fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
      })
        .then(res => {
          if (res.ok) {
            return res.json();
          }
          throw res;
        })
        .then(data => {
          setDataRec(data);
          setLegend(makeLegend(data, field));
        })
    }
  }, [group, startDate, endDate]);

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };


  return (
    <ResponsiveContainer width="95%" height={300}>
      <PieChart width={730} height={250}>
        <Pie
          data={dataRec}
          dataKey="time__sum"
          cx="50%"
          cy="50%"
          animationBegin={0}
          animationDuration={500}
          outerRadius={80}
          label={true}
        >
          {
            dataRec.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={colors[index % colors.length]}/>
            ))
          }
        </Pie>
        <Legend
          payload={legend}
        />
      </PieChart>
    </ResponsiveContainer>
  );

}

import styled from "styled-components";
import AppButton from "./AppButton";
import {AppList, colorList} from "./AppList";
const BaseWarpper = styled.div`
  max-width: 600px;
  margin: auto;
  margin-top: 100px;
`


function Home({levelAccess}) {

  const AppBrowser = [];
  if(levelAccess !== -1) {
    let Row = [];
    let i = 0;
    let j = 0;
    for (let app of AppList) {
      if (app.level >= levelAccess) {
        Row.push(
            <AppButton
                className="col-mb-4"
                key={i}
                link={app.url}
                title={app.name}
                cover={app.cover}
                inputColor={colorList[j % colorList.length].code}
            />);
        i += 1;
        j += 1;
        if (i === 3) {
          AppBrowser.push(
              <div className="row justify-content-center">
                {Row}
              </div>)
          Row = [];
          i = 0;
        }
      }
    }
    AppBrowser.push(
        <div className="row justify-content-center">
          {Row}
        </div>)
  }
  return (
      <BaseWarpper>
        <div className="container ">
          {AppBrowser}
        </div>
      </BaseWarpper>
  )
}

export default Home
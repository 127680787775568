import React, {useEffect, useState} from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import styled from "styled-components";
import Moment from 'react-moment';
import {base_url} from "../../../components/api_url/url";

const StyledCaption = styled.caption`
  color: black !important;
  font-size : 15px !important;
`



const columns = [
  { id: 'date', label: 'Date', maxWidth: 150 },
  { id: 'name', label: 'Name', minWidth: 250, maxWidth: 300},
  { id: 'time', label: 'Temps (H)', maxWidth: 120, minWidth: 120 },
  {
    id: 'category',
    label: 'Categories',
    minWidth: 170,
    maxWidth: 200,
  },
  {
    id: 'project',
    label: 'Dossier',
    minWidth: 150,
  },
  {
    id: 'created_at',
    label: 'Ajouté à',
    minWidth: 150,
  },
];


function extractData(data){
  let rows = []
  for(let i = 0; i < data.length; i++){
    const d = data[i];
    rows.push({
      date: d.date,
      name: d.name,
      time: d.time,
      category: d.category__name,
      project: d.project__name,
      created_at: new Date(d.created_at).toLocaleString('fr-FR', { timeZone: 'UTC' }),
      id: d.id,
      deleted: d.deleted,
      updated: d.updated,
      history_num: d.history_num
    })
  }
  return rows
}

function countHours(data){
  let somme = 0;
   for(let i = 0; i < data.length; i++){
     if(!data[i].deleted && !data[i].updated){
       somme += data[i].time;
     }
  }
  return somme;
}

export default function History({title, idModal, histNum}) {

  const [prestaList, setPrestaList] = useState([]);
  const [totalHours, setTotalHours] = useState(0);

  useEffect(() => {
    if(idModal && histNum) {
      let url = base_url + 'timesheet/prestaHistory/'
      url += "?hist_id=" + histNum;

      fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
      })
        .then(res => {
          if (res.ok) {
            return res.json();
          }
          throw res;
        })
        .then(data => {
          setPrestaList(extractData(data))
          setTotalHours(countHours(data))
        })
    }
  }, [idModal, histNum]);

  return (
    <Paper sx={{ margin: 'auto', maxWidth: '1200px', overflow: 'hidden'}}>
      <h3>{(title && title.typeof === "date") ? <Moment locale="fr" format="LL" >{title}</Moment>: <span>{title}</span>}</h3>
      <TableContainer >
        <Table stickyHeader aria-label="sticky table">
          {totalHours ? <StyledCaption> Nombre d'heure Total : {totalHours}H </StyledCaption> : ""}
          <TableHead >
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  sx={{ backgroundColor:"#D6D6D6" }}
                  key={column.id}
                  align={column.align}
                  style={{
                    minWidth: column.minWidth,
                    maxWidth: column.maxWidth,
                    }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {prestaList
              // eslint-disable-next-line array-callback-return
              .map((row) => {
                return (
                  <TableRow
                      hover
                      role="checkbox" tabIndex={-1}
                      key={row["id"]}
                  >
                    {columns.map((column, index) => {
                      const value = row[column.id];
                        return (
                          <TableCell
                              key={index}
                              align={column.align}
                              id={row["id"]}
                              style={{
                                height: '20px',
                                whiteSpace: 'nowrap',
                                maxWidth: column.maxWidth,
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                padding: "8px 16px",
                              }}
                          >
                            {column.format && typeof value === 'number'
                                ? column.format(value)
                                : value}
                          </TableCell>
                        );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}


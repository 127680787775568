import React, {useEffect, useState} from "react";
import { scaleOrdinal } from "d3-scale";
import { schemeTableau10} from "d3-scale-chromatic";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList,
} from "recharts";
import {base_url} from "../../../components/api_url/url";

import {getMonthStr, getWeek, getWeekLabelStr, getDayOfWeek} from "../utils/Date";

const colors = scaleOrdinal(schemeTableau10).range();


function getNumberOfDays(start, end) {
    const date1 = new Date(start);
    const date2 = new Date(end);

    // One day in milliseconds
    const oneDay = 1000 * 60 * 60 * 24;

    // Calculating the time difference between two dates
    const diffInTime = date2.getTime() - date1.getTime();

    // Calculating the no. of days between two dates
    const diffInDays = Math.round(diffInTime / oneDay);

    return diffInDays;
}

function format_dataByMonth(data, field){

  let list_month = []
  for(let i=0; i<data.length; i++){
    let curr_month = new Date(data[i].date).getMonth()
    if(!list_month.includes(getMonthStr(curr_month))) {
      list_month.push(getMonthStr(curr_month));
    }
  }
  let datakeys = []
  for(let i=0; i<data.length; i++){
    if(!datakeys.includes(data[i][field])){
      datakeys.push(data[i][field]);
    }
  }

  let data_list = []
  for(let i=0; i<list_month.length; i++){
    data_list[i] = {'month':list_month[i]};
  }

  for(let i=0; i<data.length; i++){
    let curr_month = new Date(data[i].date).getMonth()
    const index = list_month.indexOf(getMonthStr(curr_month));
    data_list[index][data[i][field]] = data[i].time__sum
  }

  return [data_list, datakeys]
}

function format_dataByWeek(data, field){

  let list_week = []
  for(let i=0; i<data.length; i++){
    let date = new Date(data[i].date)
    if(!list_week.includes(getWeekLabelStr(date))) {
      list_week.push(getWeekLabelStr(date));
    }
  }

  let datakeys = []
  for(let i=0; i<data.length; i++){
    if(!datakeys.includes(data[i][field])){
      datakeys.push(data[i][field]);
    }
  }

  let data_list = []
  for(let i=0; i<list_week.length; i++){
    data_list[i] = {'week':list_week[i]};
  }

  for(let i=0; i<data.length; i++){
    let date = new Date(data[i].date)
    const index = list_week.indexOf(getWeekLabelStr(date));
    data_list[index][data[i][field]] = data[i].time__sum
  }

  return [data_list, datakeys]
}

function format_dataByDay(data, start_date, end_date, field){
  const deltaDays = getNumberOfDays(start_date,end_date);
  let list_date = []
  for(let i=0; i<=deltaDays; i++){
    let curr_date = new Date();
    curr_date.setDate(end_date.getDate() - i);
    list_date.push(curr_date.toISOString().substr(0, 10));
  }
  let datakeys = []
  for(let i=0; i<data.length; i++){
    if(!datakeys.includes(data[i][field])){
      datakeys.push(data[i][field]);
    }
  }
  let data_list = []
  for(let i=0; i<=deltaDays; i++){
    data_list[i] = {'day':list_date[i]};
  }
  for(let i=0; i<data.length; i++){
    const index = list_date.indexOf(data[i].date);
    data_list[index][data[i][field]] = data[i].time__sum
  }
  datakeys.push("WEEKEND");
  for(let i=0; i<data_list.length; i++){
    const no_presta = Object.keys(data_list[i]).length === 1
    const dayOfWeek = new Date(data_list[i]['day']).getDay();
    const isWeekend = (dayOfWeek === 6) || (dayOfWeek  === 0); // 6
    if(no_presta && isWeekend){
      data_list[i]["WEEKEND"] = "weekend";
    }
  }
  return [data_list, datakeys]
}


export default function StackedBar({x_value, type, startDate, endDate}) {


  const [dataCat, setDataByCat] = useState([])
  const [dataCategory, setDataCategory] = useState([])
  const [rawData, setRawData] = useState([])

  let end_date;
  let start_date;
  if(startDate && endDate){
    start_date = new Date(startDate);
    end_date = new Date(endDate);
  } else {
    end_date = new Date();
    start_date = new Date();
    start_date.setDate(end_date.getDate() - 6);
  }

  let target = ""
  let field = ""
  if(type === 'category'){
    target = 'personalHoursByCat/'
    field = 'category__name'
  }else if(type === 'project') {
    target = 'personalHoursByProject/'
    field = 'project__name'
  }

  React.useEffect(() => {
    if(type) {

      let url = base_url + 'timesheet/'
      url += target
      url += "?ByDay=true"

      url += "&end_date=" + end_date.toISOString().substr(0, 10);
      url += "&start_date=" + start_date.toISOString().substr(0, 10);
      fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
      })
        .then(res => {
          if (res.ok) {
            return res.json();
          }
          throw res;
        })
        .then(data => {
          setRawData(data)
        })
    }
  }, [type, startDate, endDate]);

  useEffect(() => {
    let tmp;
    switch (x_value){
      case 'day':
        tmp = format_dataByDay(rawData, start_date, end_date, field);
        break;
      case 'week':
        tmp = format_dataByWeek(rawData, field);
        break;
      case 'month':
        tmp = format_dataByMonth(rawData, field);
        console.log(tmp[0]);
        break;
    }
    console.log(tmp)
    if(tmp) {
      setDataByCat(tmp[0]);
      setDataCategory(tmp[1]);
    }
  }, [x_value, rawData])


  return (
      <ResponsiveContainer width="95%" height={400}>
        <BarChart
            data={dataCat}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 5
            }}
        >
          <CartesianGrid strokeDasharray="3 3"/>
          <XAxis dataKey={x_value}/>
          <YAxis/>
          <Tooltip/>
          <Legend/>
          {dataCategory.map((cat, index) => (
            <Bar dataKey={cat} key={"bar-" + index} stackId="a" fill={colors[index % colors.length]} >
              <LabelList
                dataKey={cat}
                position="center"
                fill="white"
              />
            </Bar>
            ))}
        </BarChart>
      </ResponsiveContainer>
  );

}

import styled from "styled-components"
import {useEffect, useState} from "react";
import Container from "react-bootstrap/Container";
import TableToolBar from "../TimeSheet/ToolBar/TableToolBar";
import TablesByDate from "../TimeSheet/Table/TablesByDate";
import TablesByKey from "../TimeSheet/Table/TablesByKey";
import * as React from "react";

const StyledDiv = styled(Container)`
  margin-bottom : 100px;
`

const columnsId = {
  date: 'date',
  name: 'name',
  user: 'user',
  time: 'time',
  category: 'category',
  project: 'project',
  created_at: 'created_at',
  id: 'id',
  deleted: 'deleted',
  history_num: 'history_num'
};


export default function PersonalTimeSheet(){

  const [trigger, setTrigger] = useState(0)
  const [type, setType] = useState('none')
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const [entite, setEntite] = useState('')
  const [service, setService] = useState('')

  const [projectFilter, setProjectFilter] = useState("")
  const [categoryFilter, setCategoryFilter] = useState("")
  const [workerFilter, setWorkerFilter] = useState("")

  return(
    <StyledDiv>
      <TableToolBar
          entite={entite}
          service={service}
          setType={setType}
          setTrigger={setTrigger}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          setProject={setProjectFilter}
          setCategory={setCategoryFilter}
          setWorker={setWorkerFilter}
          setEntite={setEntite}
          setService={setService}
          level={1}
      />
      {type === 'day' || type === 'month' || type === 'week' || type ==='none' ?
        <TablesByDate
            type={type}
            trigger={trigger}
            setTrigger={setTrigger}
            startDate={startDate}
            endDate={endDate}
            project={projectFilter}
            category={categoryFilter}
            entite={entite}
            service={service}
            worker={workerFilter}
            level={1}
            columnsId={columnsId}
        />:
        <TablesByKey
            trigger={trigger}
            setTrigger={setTrigger}
            startDate={startDate}
            endDate={endDate}
            type={type}
            project={projectFilter}
            category={categoryFilter}
            entite={entite}
            service={service}
            worker={workerFilter}
            level={1}
            columnsId={columnsId}
        />
      }
    </StyledDiv>
  )
}

import SimpleTable from "../../../components/Table/SimpleTable";
import { useEffect, useState} from "react";
import {base_url} from "../../../components/api_url/url";

function createData(name, firstName, entite, service, id) {
  return {name, firstName, entite, service, id};
}

function formatData(data){
  let rows = []
  for(let i=0; i<data.length; i++){
    const dataRow = data[i]
    rows.push(createData(
        dataRow.lastName,
        dataRow.firstName,
        dataRow.entite,
        dataRow.service,
        dataRow.id,
        ))
  }
  return rows
}

const headCells = [
  {
    id: 'name',
    label: 'Nom',
  },
  {
    id: 'firstName',
    label: 'Prénom',
  },
  {
    id: 'entite',
    label: 'Entité',
  },
  {
    id: 'service',
    label: 'Service',
  },
];

export default function UserTable({setUser, trigger}) {

  const [data, setData] = useState([])

  useEffect(() => {
    const url = base_url + 'user/userList/'
    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      },
    })
      .then(res => {
        if (res.ok) {
          return res.json();
        }
        throw res;
      })
      .then(data => {
        const formatedData = formatData(data)
        setData(formatedData);
      })
      .catch(error => {
        console.error("Error posting data project: ", error);
      })
  }, [trigger]) ;

  return(
    <SimpleTable
      rows={data}
      width={"100%"}
      height={"485px"}
      headCells={headCells}
      setSelected={setUser}
    />
  )

}
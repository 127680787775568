import styled from "styled-components";

export const BackGround = styled.div`
  position: absolute;
  width: 100%;
  height: 100vh;
  background-color: #045de9;
  background-image: linear-gradient(315deg, #045de9 0%, #09c6f9 74%);
`;

export const Warpper = styled.div`
  width:75%;
  height: 80%;
  background: white;
  margin: auto;
  margin-top: 75px;
  display: flex;
  flex-direction: row;
  border-radius: 10px;
  box-shadow: 20px 20px 45px #666
`

export const FormWarpper = styled.div`
  max-width: 500px;
  width: 45%;
  display: flex;
  flex-direction: column
`

export const LogoWarpper = styled.div` 
  margin: auto;
  height: 30%;
`

export const StyledForm = styled.form`
    height: 40%;
    width: 80%;
    margin: auto;
`;

export const ImgWarpper = styled.div`
  height: 100%;
  width: 55%;
  overflow: hidden;
`

export const StyledImg = styled.img`
  margin-bottom: 80px;
`
export const StyledButton = styled.button`
    padding: 5px 60px;
    background: blue;
    color: white;
    font-size: 22px;
    border: none;
    border-radius: 20px;
    text-align: center;
`

export const StyledPicture = styled.img`
    height: 100%;
`

export const ButtonWarpper = styled.div`
  text-align: center;
  margin-top: 20px;
`
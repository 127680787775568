import {Fragment, useState} from "react";
import * as React from "react";
import TimeTable from "../TimeTable"
import ModalForm from "../Form/ModalForm";
import ModalInfo from "../../Presta/ModalInfo"
import {dispatchByMonth, dispatchByDay,  dispatchByWeek} from "../utils/TablesByDate";
import {getWeekLabel, getMonthStr, getDayOfWeek} from "../utils/Date"
import {base_url} from "../../../components/api_url/url";

export default function TablesByDate(props) {
  const {trigger, setTrigger, type, startDate, endDate, project, category, entite, service, worker} = props
  const [modalId, setModalId] = useState();
  const [tables, setTables] = useState([]);
  const [show, setShow] = useState(false);


  React.useEffect(() => {
    if(startDate && endDate) {
      let url;
      if (props.level === 1) {
        url = base_url + 'timesheet/prestationList/'
      } else {
        url = base_url + 'timesheet/personalPresta/'
      }

      const start_date = new Date(startDate);
      const end_date = new Date(endDate);

      url += "?end_date=" + end_date.toISOString().substr(0, 10);
      url += "&start_date=" + start_date.toISOString().substr(0, 10);

      if (project && project !== "DEFAULT") {
        url += "&project=" + project;
      }

      if (category && category !== "DEFAULT") {
        url += "&category=" + category;
      }

      if (entite && entite !== "DEFAULT") {
        url += "&entite=" + entite;
      }

      if (service && service !== "DEFAULT") {
        url += "&service=" + service;
      }

      if (worker && worker !== "DEFAULT") {
        url += "&worker=" + worker;
      }

      fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
      })
          .then(res => {
            if (res.ok) {
              return res.json();
            }
            throw res;
          })
          .then(data => {
            let tmp
            // eslint-disable-next-line default-case
            switch (type) {
              case 'none':
                tmp = [[data], ["Toutes les prestations"]]
                break;
              case 'day':
                tmp = dispatchByDay(data);
                break;
              case 'week':
                tmp = dispatchByWeek(data);
                break;
              case 'month':
                tmp = dispatchByMonth(data);
                break
            }

            const data_list = tmp[0];
            const list_group = tmp[1];

            let tableByDay = [];

            for (let i = 0; i < list_group.length; i++) {
              let title;
              let titleType;
              switch (type) {
                case 'none':
                  title = list_group[i];
                  titleType = 'text'
                  break;
                case 'day':
                  title = list_group[i];
                  titleType = 'date'
                  break;
                case 'month':
                  title = getMonthStr(list_group[i]);
                  titleType = 'text'
                  break
                case 'week' :
                  tmp = getWeekLabel(getDayOfWeek(list_group[i]));
                  titleType = 'week'
                  title = tmp[0] + " " + tmp[1];
                  break
              }

              tableByDay.push(
                  <TimeTable
                      key={i}
                      title={title}
                      titleType={titleType}
                      setShow={setShow}
                      data={data_list[i]}
                      setModalId={setModalId}
                      columnsId={props.columnsId}
                  />
              )
            }

            setTables(tableByDay)
          })
    }
  }, [trigger, type, startDate, endDate, project, category, service, worker, entite]);


  return(
      <div>
        {tables}
        {props.level === 1 ?
          <ModalInfo
              show={show}
              setShow={setShow}
              modalId={modalId}
              trigger={trigger}
              setTrigger={setTrigger}
          />:
          <ModalForm
              show={show}
              setShow={setShow}
              modalId={modalId}
              trigger={trigger}
              setDuplicateData = {props.setDuplicateData}
              setTrigger={setTrigger}
          />
        }
      </div>
  )
}
import SimpleTable from "../../../components/Table/SimpleTable";
import {useEffect, useState} from "react";
import {base_url} from "../../../components/api_url/url";

function createData(date, user, name, category, time) {
  return { date, user, name, category, time};
}

function formatData(data){
  let rows = []
  for(let i=0; i<data.length; i++){
    const dataRow = data[i]
    if(!dataRow.deleted) {
      rows.push(createData(
          dataRow.date,
          dataRow.user.firstName + " " + dataRow.user.lastName,
          dataRow.name,
          dataRow.category,
          dataRow.time,
      ))
    }
  }
  return rows
}

const headCells = [
  {
    id: 'date',
    label: 'DATE',
  },
  {
    id: 'user',
    label: 'PRESTATAIRE',
  },
  {
    id: 'name',
    label: 'NOM',
  },
  {
    id: 'category',
    label: 'CATEGORIE',
  },
  {
    id: 'time',
    label: 'TEMPS(H)',
  },
];

export default function AllPrestaTable({project}) {

  const [data, setData] = useState([]);

  useEffect(() => {
    if (project) {
      const url = base_url + 'timesheet/prestationList/?project=' + project;
      fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
      })
        .then(res => {
          if (res.ok) {
            return res.json();
          }
          throw res;
        })
        .then(data => {
          const formatedData = formatData(data)
          setData(formatedData);
        })
        .catch(error => {
          console.error("Error posting data project: ", error);
        })
    }
  }, [project]);

  return (
      <SimpleTable
          rows={data}
          headCells={headCells}
          width={"90%"}
          height={"500px"}
      />
  )
}


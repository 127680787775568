import {Col, Form, InputGroup, Row, ToggleButton} from "react-bootstrap";
import SelectData from "../../../components/Form/SelectForm";
import {useState} from "react";
import styled from "styled-components";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import {getWeek} from "../../TimeSheet/utils/Date";

const StyledRow = styled(Row)`
   background: white;
   padding: 10px 0;
   margin: 20px 0;
   border: 1px solid #D6D6D6;
   border-radius: 5px;
`

const statusList = [
  {value:'DEFAULT', label:'------'},
  {value:0, label:'en attente'},
  {value:1, label:'en cours'},
  {value:2, label:'fini'},
  {value:3, label:'archivé'}
]

export default function FilterToolbar(props) {

  const {group, setGroup, setStatus, setEntity} = props

  const radiosGroup = [
    { name: 'RadioType', value: false, label: 'Non'},
    { name: 'RadioType', value: true, label: 'Oui'},
  ];

  return(
    <StyledRow>
      <Form.Group as={Col}>
        <label >Entite :</label>
        <SelectData
        defaultValue="------"
        setValue={setEntity}
        target="entite/"
        />
      </Form.Group>
      <Form.Group as={Col}>
        <label >Status :</label>
        <Form.Select onChange={(e) => setStatus(e.target.value)}>
          {statusList.map((status, idx) => {
            return(
              <option key={idx} value={status.value}>{status.label}</option>
            )
          }

          )}
        </Form.Select>
      </Form.Group>
       <Form.Group as={Col}>
        <label >Grouper :</label>
        <ButtonGroup>
        {radiosGroup.map((radio, idx) => (
          <ToggleButton
            key={idx}
            id={`radioType-${idx}`}
            type="radio"
            variant= 'outline-primary'
            name={radio.name}
            value={radio.value}
            checked={group === radio.value}
            onChange={() => setGroup(!group)}
          >
            {radio.label}
          </ToggleButton>
        ))}
      </ButtonGroup>
      </Form.Group>
    </StyledRow>
  )
}
import {useState} from "react";
import Browser from "./Browser";
import Info from "./Info"
import styled from "styled-components";
import Map from "./Map"

const MainWarpper = styled.div`
    display: flex;
    flex-direction: row;
    margin: 50px;
  `

const InfoWarpper = styled.div`
  border-left: 1px solid #666;
  margin-left: 20px;
  width: 100%;
  min-height: 80vh;
  background: white;
`

const BrowserWarpper = styled.div`
  max-width: 500px;
  width: 40%;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  overflow: overlay;
`

export default function Project() {

  const [project, setProject] = useState(null)

  return (
    <div>
      <MainWarpper>
        <BrowserWarpper>
          <Browser
            setProject =  {setProject}
          />
        </BrowserWarpper>
        <InfoWarpper>
          {project &&
            <Info
                project={project}
            />
          }
        </InfoWarpper>
      </MainWarpper>
    </div>


  )

}



import {Fragment, useEffect, useState} from "react";
import Description from "../Info/Description";
import OneBarChart from "./OneBarChart";
import styled from "styled-components";
import DividerLine from "../../../components/Divider/DividerLine";

const StyledH3 = styled.h3`
  margin-left: 40px;
`

export default function TimeService({projectID}) {

  return (
      <Fragment>
        <StyledH3>Temps par Service :</StyledH3>
        <OneBarChart projectID={projectID}/>
        <DividerLine />
      </Fragment>
  )

}
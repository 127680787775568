import CustomButton from "./customButton";
import logoProloge from '../../assets/Logo.png';
import logoProgimo from '../../assets/logo.webp';
import "./styles.css";
import Navbar from 'react-bootstrap/Navbar';
import {NavDropdown} from "react-bootstrap";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import styled from "styled-components";
import {Fragment} from "react";
import {tokenIsExpired} from "../../pages/LoginPage/utility";

const StyledDiv = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0 50px;
`

function Header({levelAccess, entite}) {

  let logo = logoProloge
  if(entite == "Progimo"){
    logo = logoProgimo
  }
  const logged = tokenIsExpired();

  return (
      <Navbar bg="dark" variant="dark" expand="lg">
        <StyledDiv>
          <Navbar.Brand href="/">
            <img
              src={logo}
              height="40"
              className="d-inline-block align-top"
              alt="React Bootstrap logo"
          /></Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href="/">Home</Nav.Link>
              {levelAccess === 1 &&
                <Nav.Link href="/project">Dossiers</Nav.Link>
              }
              {levelAccess <= 3 &&
                <NavDropdown title="Prestations" id="basic-nav-dropdown">
                  <NavDropdown.Item href="/Timesheet">Mes prestations</NavDropdown.Item>
                  {levelAccess === 2 &&
                  <Fragment>
                    <NavDropdown.Item href="/MyService">Mon service</NavDropdown.Item>
                  </Fragment>
                  }
                  {levelAccess === 1 &&
                  <Fragment>
                    <NavDropdown.Divider/>
                    <NavDropdown.Item href="/AllPresta">Toutes les prestations</NavDropdown.Item>
                    <NavDropdown.Divider/>
                    <NavDropdown.Item href="/Category">Catégorie</NavDropdown.Item>
                  </Fragment>
                  }
                </NavDropdown>
              }
              {levelAccess === 1 &&
                <Nav.Link href="/Worker">Collaborateurs</Nav.Link>
              }
            </Nav>
          </Navbar.Collapse>
          {logged ?
            <Nav.Link href="/Worker/MyProfil">Mon Profil</Nav.Link>:
            <CustomButton
            link="Login/"
            className="login_button"
            title="Connectez-vous"
            />
          }
        </StyledDiv>
      </Navbar>
  )

}

export default Header
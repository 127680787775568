import PrestaForm from "./Form/PrestaForm";
import styled from "styled-components"
import {useEffect, useState} from "react";
import Container from "react-bootstrap/Container";
import CustomAccordion from "../../components/Accordion";
import TableToolBar from "./ToolBar/TableToolBar";
import TablesByDate from "./Table/TablesByDate";
import TablesByKey from "./Table/TablesByKey";
import * as React from "react";
import ChartModule from "./ChartModule";
import {base_url} from "../../components/api_url/url";

const StyledDiv = styled(Container)`
  margin-bottom : 100px;
`

export default function PersonalTimeSheet(){

  const [trigger, setTrigger] = useState(0)
  const [type, setType] = useState('none')
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [entite, setEntite] = useState('')
  const [service, setService] = useState('')

  const [projectFilter, setProjectFilter] = useState("")
  const [categoryFilter, setCategoryFilter] = useState("")

  const [duplicateData, setDuplicateData] = useState(null)

  useEffect(() => {
    const url = base_url + 'user/userInfo/?user=-1'
    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      },
    })
      .then(res => {
        if (res.ok) {
          return res.json();
        }
        throw res;
      })
      .then(data => {
        setEntite(data.entite_id);
        setService(data.service_id);
      })
      .catch(error => {
        console.error("Error posting data Address: ", error);
      })
  }, []);

  if(entite && service){
    return(
      <StyledDiv>
        <PrestaForm
            service={service}
            entite={entite}
            setTrigger={setTrigger}
            trigger={trigger}
            duplicateData={duplicateData}
        />
        <CustomAccordion
          title="Analyse"
          ID='panel1'
        >
          <ChartModule/>
        </CustomAccordion>
        <TableToolBar
            entite={entite}
            service={service}
            setType={setType}
            setTrigger={setTrigger}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            setProject={setProjectFilter}
            setCategory={setCategoryFilter}
        />
        {type === 'day' || type === 'month' || type === 'week' || type ==='none' ?
          <TablesByDate
              type={type}
              trigger={trigger}
              setTrigger={setTrigger}
              startDate={startDate}
              endDate={endDate}
              project={projectFilter}
              category={categoryFilter}
              setDuplicateData={setDuplicateData}
          />:
          <TablesByKey
              trigger={trigger}
              setTrigger={setTrigger}
              startDate={startDate}
              endDate={endDate}
              type={type}
              project={projectFilter}
              category={categoryFilter}
              setDuplicateData={setDuplicateData}
          />
        }
      </StyledDiv>
    )}
  else {
    return ("");
  }
}

import React, {Fragment, useEffect, useState} from "react";
import {Form} from "react-bootstrap";
import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import SelectData from "../../../components/Form/SelectForm";
import Button from "react-bootstrap/Button";
import {base_url} from "../../../components/api_url/url";

const ButtonWarpper = styled.div`
  margin: 30px auto;
  width: 400px;
`

const SaveButton = styled.button`
  width: 100%;
  border-radius: 5px;
  padding: 10px;
  border: none;
  background: #003391;  
  color: white;
  border-radius: 25px;
`

const DesactiveButton = styled.button`
  width: 100%;
  border-radius: 5px;
  padding: 10px;
  border: none;
  background: #dc3545;  
  color: white;
  border-radius: 25px;
`

const StyledForm = styled(Form)`
  margin: 20px;
`

const StyledGroup = styled(Form.Group)`
  margin: 10px 0;
`

const TextWarpper = styled.div`
  padding: 8px;
  border: 1px solid #BBBBBB;
  border-radius: 5px;
`

const FlexDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const EditIcon = styled(FontAwesomeIcon)`
  color: #277DA1;
  &:hover {
    color: black;
  }
`

export default function Info({user, trigger, setTrigger}) {

  const [name, setName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [email, setEmail] = useState("");
  const [salary, setSalary] = useState("");
  const [entity, setEntity] = useState("");
  const [service, setService] = useState("");
  const [data, setData] = useState();

  const [showEntiteModal, setShowEntiteModal] = useState(false);
  const [showServiceModal, setShowServiceModal] = useState(false);

  const saveEntity = () => {
    const serviceInput={
      user: data.id,
      entite: entity,
      entiteUser: data.entiteUserId
    };
    const url = base_url + 'user/entiteUser/';
    fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      },
      body: JSON.stringify(serviceInput)
    })
      .then(res => {
        if (res.ok) {
          setTrigger(trigger+1);
          return res.json();
        }
        throw res;
      })
      .catch(error => {
        console.error("Error posting data project: ", error);
      })
    setShowEntiteModal(false);
  }

  const saveService = () => {
    const serviceInput={
      user: data.id,
      service: service,
      serviceUser: data.serviceUserId
    };
    const url = base_url + 'user/serviceUser/';
    fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      },
      body: JSON.stringify(serviceInput)
    })
      .then(res => {
        if (res.ok) {
          setTrigger(trigger+1);
          return res.json();
        }
        throw res;
      })
      .catch(error => {
        console.error("Error posting data project: ", error);
      })
    setShowServiceModal(false);
  }

  const handleCloseEntity = () => {
    setShowEntiteModal(false);
  }

  const handleCloseService = () => {
    setShowServiceModal(false);
  }

  const handleEditEntity = () => {
    setShowEntiteModal(true);
  }

  const handleEditService = () => {
    setShowServiceModal(true);
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const userInput={
      name: name,
      firstName: firstName,
      email: email,
      salary: salary,
      id: data.id,
    };
    const url = base_url + 'user/userInfo/?user=' + user;
    fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      },
      body: JSON.stringify(userInput)
    })
      .then(res => {
        if (res.ok) {
          alert("les données utilisateur ont bien été mis à jour")
          setTrigger(trigger+1 )
          return res.json();
        }
        throw res;
      })
      .catch(error => {
        console.error("Error posting data project: ", error);
      })
  }

  useEffect(() => {
    if(user) {
      const url = base_url + 'user/userInfo/?user=' + user;
      fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
      })
        .then(res => {
          if (res.ok) {
            return res.json();
          }
          throw res;
        })
        .then(data => {
          setData(data)
          setName(data.lastName)
          setFirstName(data.firstName)
          setSalary(data.salary)
          setEntity(data.entite_id)
          setService(data.service_id)
          setEmail(data.email)
        })
        .catch(error => {
          console.error("Error posting data project: ", error);
        })
    }
  }, [user, trigger])

  return (
    <StyledForm onSubmit={handleSubmit}>
      <h3>Informations sur l'utilisateur :</h3>
      <StyledGroup>
        <Form.Label>Nom :</Form.Label>
        <Form.Control
            type="text"
            value={name}
            onChange={e => setName(e.target.value)}
        />
      </StyledGroup>
      <StyledGroup>
        <Form.Label>Prénom :</Form.Label>
        <Form.Control
            type="text"
            value={firstName}
            onChange={e => setFirstName(e.target.value)}
        />
      </StyledGroup>
      <StyledGroup>
        <Form.Label>Email :</Form.Label>
        <Form.Control
            type="text"
            value={email}
            onChange={e => setEmail(e.target.value)}
        />
      </StyledGroup>
      <StyledGroup>
        <Form.Label>entité:</Form.Label>
        <TextWarpper>
          <FlexDiv>
            {data && <Fragment>{data.entite}<EditIcon onClick={handleEditEntity} icon="fas fa-pen"/></Fragment>}
          </FlexDiv>
        </TextWarpper>
      </StyledGroup>
      <StyledGroup>
        <Form.Label>Service:</Form.Label>
        <TextWarpper>
          <FlexDiv>
            {data && <Fragment>{data.service}<EditIcon onClick={handleEditService} icon="fas fa-pen"/></Fragment>}
          </FlexDiv>
        </TextWarpper>
      </StyledGroup>
      <ButtonWarpper>
        <SaveButton type="submit">Enregister</SaveButton>
      </ButtonWarpper>
      <ButtonWarpper>
        <DesactiveButton type="submit">Désactiver</DesactiveButton>
      </ButtonWarpper>

      <Modal show={showEntiteModal} onHide={handleCloseEntity}>
        <Modal.Body>
          <Form>
            <StyledGroup as={Col}>
              <Form.Label>Entite:</Form.Label>
              <SelectData
                value={entity}
                setValue={setEntity}
                defaultId={entity}
                target="entite/"
              />
            </StyledGroup>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseEntity}>
            Annuler
          </Button>
          <Button variant="primary" onClick={saveEntity}>
            Enregister
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showServiceModal} onHide={handleCloseService}>
        <Modal.Body>
          <Form>
            <StyledGroup as={Col}>
              <Form.Label>Service:</Form.Label>
              <SelectData
                value={service}
                setValue={setService}
                defaultId={service}
                target="service/"
              />
            </StyledGroup>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseService}>
            Annuler
          </Button>
          <Button variant="primary" onClick={saveService}>
            Enregister
          </Button>
        </Modal.Footer>
      </Modal>

    </StyledForm>

  )
}
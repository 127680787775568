import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import {base_url} from "../../../components/api_url/url";
import { styled } from '@mui/material/styles';
import Col from 'react-bootstrap/Col'
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

const StyledContainer = styled(Container)`
  max-width: 560px;
  margin-top: 100px;
  border: solid 1px #666;
  padding: 30px;
  border-radius: 5px;
  box-shadow: 10px 10px 12px rgb(0 ,0 ,0 , 0.32)
`

const ButtonBar = styled('div')`
  display: flex;
  flex-direction : row;
  justify-content: space-between;
`

export default function NewForm() {
  const [name, setName] = useState('');
  const [entite, setEntite] = useState('');
  const [address, setAddress] = useState('');
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const begin = new Date().toISOString().substr(0, 10);
  const [error, setError] = useState(false);

  const navigate = useNavigate();

  const [listEntite, setListEntite] = useState([]);

  useEffect(() => {
    fetch(base_url + 'entite/', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      },
    })
    .then(res => {
      if (res.ok){
        return res.json();
      }
      throw res;
    })
    .then(data =>  {
      setListEntite(data);
    })
    .catch(error => {
      console.error("Error fetching data: ", error);
      setError(true);
    })
  }, []);

  const validate = () => {
    let status = true
    if (!name) {
      status = false
    }
    if (!entite) {
      status = false
    }
    return status
  }

  const new_project = (e, data) => {
    let project
    if(validate()) {
      if (data) {
        project = {
          name: name,
          entite: entite,
          address: data.id,
          begin: begin,
        }
      } else {
        project = {
          name: name,
          entite: entite,
          begin: begin,
        }
      }
      fetch(base_url + 'project/project/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        body: JSON.stringify(project)
      })
          .then(res => {
            if (res.ok) {
              return res.json();
            }
            throw res;
          })
          .then(data => {
            if (data) {
              console.log("data received: ", data)
              return (navigate('/Project'))
            }
          })
          .catch(error => {
            console.error("Error posting data project: ", error);
            setError(true)
          })
    }
  }

  const onSubmit = e => {
    e.preventDefault();

    const address_data = {
      text: address,
      lat: latitude,
      lon: longitude
    }

    fetch(base_url + 'project/address/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      },
      body: JSON.stringify(address_data)
    })
      .then(res => {
        if (res.ok) {
          return res.json();
        }
        throw res;
      })
      .then(data => {
        new_project(e, data)
      })
      .catch(error => {
        console.error("Error posting data Address: ", error);
        setError(true)
      })

  }

  return (
    <StyledContainer>
      <Form onSubmit={onSubmit}>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Control
              type="text"
              placeholder="nom du dossier"
              required
              onChange={e => setName(e.target.value)}
          />
          <Form.Text className="text-muted">
          </Form.Text>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>Entite:</Form.Label>
          <Form.Select
              onChange={e => setEntite(e.target.value)}
              aria-label="Default select example"
              required
          >
            <option value="">Entite</option>
            {listEntite.map((entite, index) => (
              <option key={index} value={entite.id}>
                {entite.name}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>Adresse : (optionel)</Form.Label>
          <Form.Control
              type="text"
              placeholder="Adresse"
              onChange={e => setAddress(e.target.value)}
          />
        </Form.Group>
        {/*à réactiver pour la carte*/}
        {/*<Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicPassword">
            <Form.Control
                type="text"
                placeholder="Latitude (optionel)"
                onChange={e => setLatitude(e.target.value)}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formBasicPassword">
            <Form.Control
                type="text"
                placeholder="Longitude (optionel)"
                onChange={e => setLongitude(e.target.value)}
            />
          </Form.Group>
          <Form.Text className="text-muted">
            La latitude et la longitude permettent de représenter les projets sur la carte
          </Form.Text>
        </Row>*/}
          <Form.Control.Feedback style={{ display: error ? "block" : "none" }} type="invalid">
            "il y a eu un problème dans l'envoie des données"
          </Form.Control.Feedback>
        <ButtonBar>
            <Button variant="primary" type="submit">
              Enregister
            </Button>
            <a href={"/Project"}>
              <Button variant="secondary">
                Retour
              </Button>
            </a>
        </ButtonBar>
      </Form>
    </StyledContainer>
  );
}

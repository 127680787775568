import {useState, useRef} from "react";
import UserTable from "./UserTable";
import styled from "styled-components";
import Info from "./Info";
import NewForm from "../NewForm";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { CSSTransition } from 'react-transition-group';
import './style.css';

const MiniPage = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 30px 30px;
`

const LeftPage = styled.div`
  width: 55%;
  min-height: 80vh;
`

const InviteDiv = styled.div`
  height: 200px;
  width: 100%;
  border: 2px solid #BBB;
  border-radius: 5px;
  box-shadow: 4px 4px 8px rgba(0,0,0,0.25);
  margin-bottom: 30px;
  background: white;
`

const InfoDiv = styled.div`
  min-height: 400px;
  width: 100%;
  border: 2px solid #BBB;
  border-radius: 5px;
  box-shadow: 4px 4px 8px rgba(0,0,0,0.25);
  background: white;
`

const RightPage = styled.div`
  width: 35%;
  min-height: 80vh;
`

const ButtonWarpper = styled.div`
  margin: 30px auto;
  width: 400px;
`

const Button = styled.button`
  width: 400px;
  border-radius: 5px;
  padding: 10px;
  border: none;
  background: #003391;  
  color: white;
  border-radius: 25px;
  font-weight: bold;
  text-decoration: none;
  &:hover{
    background:cadetblue;
    color: white;
  }
`


export default function AllUser() {

  const [user, setUser] = useState();
  const [trigger, setTrigger] = useState(0);
  const [modalShow, setModalShow] = useState(false);
  const nodeRef = useRef(null);

  return (
    <MiniPage>
      <LeftPage>
        <ButtonWarpper>
          <Button onClick={() => setModalShow(true)}>
            Nouveau Utilisateur <FontAwesomeIcon icon="fas fa-plus" />
          </Button>
        </ButtonWarpper>
      <UserTable
        setUser={setUser}
        trigger={trigger}
      />
      </LeftPage>
      <CSSTransition nodeRef={nodeRef} in={user} timeout={500} unmountOnExit classNames="my-node">
        <RightPage ref={nodeRef}>
          <InfoDiv>
            <Info
                user={user}
                trigger={trigger}
                setTrigger={setTrigger}
            />
          </InfoDiv>
        </RightPage>
      </CSSTransition>
      <NewForm
        show={modalShow}
        onHide={() => setModalShow(false)}
        trigger={trigger}
        setTrigger={setTrigger}
      />
    </MiniPage>
  )
}
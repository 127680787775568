import {useState, useEffect, Fragment} from "react";
import Description from "./Description"
import TimeService from "../TimeService";
import styled from "styled-components";
import {base_url} from "../../../components/api_url/url";
import CategoryTable from "../Table/CategoryTable";
import WorkerTable from "../Table/WorkerTable";
import DividerLine from "../../../components/Divider/DividerLine";
import SelectData from "../../../components/Form/SelectForm";
import WorkerCategoryTable from "../Table/WorrkerCategoryTable";
import AllPrestaTable from "../Table/AllPrestaTable";

const StyledH3 = styled.h3`
  margin-left: 40px;
`
const StyledH4 = styled.h4`
  margin-left: 20px;
`

const CenteredH4 = styled(StyledH4)`
  text-align: center;
`

const TableDiv = styled.div`
  padding: 0 20px;
  margin-bottom: 20px;
`

const MiniPage = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`


export default function Project({project}) {

  const [details, setDetails] = useState();
  const [totalTime, setTotalTime] = useState("");
  const [error, setError] = useState(false);
  const [trigger, setTrigger] = useState(0);
  const [userInfo, setUserInfo] = useState();
  const [worker, setWorker] = useState("");

  useEffect(() => {
    if(project) {
      const url1 = base_url + 'project/project/' + project + "/";
      fetch(url1, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
      })
        .then(res => {
          if (res.ok) {
            return res.json();
          }
          throw res;
        })
        .then(data => {
          setDetails(data);
        })
        .catch(error => {
          console.error("Error posting data project: ", error);
          setError(true);
        })
      let url2 = base_url + 'timesheet/totalTimeProject/?project=';
      url2 += project
      fetch(url2, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
      })
        .then(res => {
          if (res.ok) {
            return res.json();
          }
          throw res;
        })
        .then(data => {
          setTotalTime(data.time__sum);
        })
        .catch(error => {
          console.error("Error posting data project: ", error);
          setError(true);
        })
    }
  }, [project, trigger]) ;

  useEffect(() => {
    if(worker) {
      const url = base_url + 'detailsUser/' + worker + "/";
      fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
      })
        .then(res => {
          if (res.ok) {
            return res.json();
          }
          throw res;
        })
        .then(data => {
          setUserInfo(data);
        })
        .catch(error => {
          console.error("Error posting data project: ", error);
        })
    } else {
      setUserInfo("")
    }
  }, [worker]) ;

  return (
      <Fragment>
        {details &&
          <Description
              details={details}
              trigger={trigger}
              setTrigger={setTrigger}
          />
        }
        <StyledH3>Temps total : {totalTime}H</StyledH3>
        {details && <TimeService projectID={details.id}/>}
        <MiniPage>
          <TableDiv>
            <StyledH3>prestations par Catégorie:</StyledH3>
            <CategoryTable project={project}/>
          </TableDiv>
          <TableDiv>
            <StyledH3>prestations par Collaborateur:</StyledH3>
            <WorkerTable project={project}/>
          </TableDiv>
        </MiniPage>
        <DividerLine/>
        <MiniPage>
          <TableDiv>
            <StyledH4>prestations par Catégorie de:</StyledH4>
            <SelectData
              defaultValue="Nom"
              setValue={setWorker}
              target={"project/userList/" + project + '/'}
              keysOption={["user__firstName","user__lastName"]}
              value={worker}
            />
          </TableDiv>
          {(userInfo && worker !== "DEFAULT") &&
            <TableDiv>
                <StyledH4>{userInfo.firstName} {userInfo.lastName}</StyledH4>
                <WorkerCategoryTable
                project={project}
                worker={worker}
                />
            </TableDiv>
          }
        </MiniPage>
        <DividerLine/>
        <CenteredH4>Toutes les prestations du dossier:</CenteredH4>
        <AllPrestaTable
          project={project}
        />
      </Fragment>
  )

}

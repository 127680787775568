import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend, LabelList
} from "recharts";
import React, {useState} from "react";
import { scaleOrdinal } from "d3-scale";
import { schemeTableau10} from "d3-scale-chromatic";
import {base_url} from "../../../components/api_url/url";


function formatData(rawData) {
  let dataKeys = []
  for(let i=0; i<rawData.length; i++){
    dataKeys.push(rawData[i]["category__service__name"]);
  }
  let data = [{}]
  for(let i=0; i<rawData.length; i++){
    data[0][rawData[i].category__service__name] = rawData[i].time__sum
  }
  return [data, dataKeys]
}

const colors = scaleOrdinal(schemeTableau10).range();

export default function OneBarChart({projectID}) {

  const [data, setData] = useState([]);
  const [dataKeys, setDataKeys] = useState([]);

  React.useEffect(() => {
    let url = base_url + 'timesheet/hoursByService/?project='
    url += projectID
    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      },
    })
      .then(res => {
        if (res.ok) {
          return res.json();
        }
        throw res;
      })
      .then(rawData => {
        let formatedData = formatData(rawData)
        setData(formatedData[0])
        setDataKeys(formatedData[1])
      })
  }, [projectID]);

  return (
    <ResponsiveContainer
        width="95%"
        height={100}
    >
      <BarChart
        layout="vertical"
        data={data}
        margin={{
          top: 20,
          right: 0,
          left: 70,
          bottom: 20,
        }}
      >
        <XAxis hide type="number"/>
        <YAxis hide dataKey="name" reversed type="category"/>
        <Tooltip />
        <Legend />
        {dataKeys.map((serv, index) => (
          <Bar dataKey={serv} key={index} stackId="a" fill={colors[index % colors.length]}>
            <LabelList
              dataKey={serv}
              position="center"
              fill="white"
            />
          </Bar>
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
}
export function getNumberOfDays(start, end) {
    const date1 = new Date(start);
    const date2 = new Date(end);

    // One day in milliseconds
    const oneDay = 1000 * 60 * 60 * 24;

    // Calculating the time difference between two dates
    const diffInTime = date2.getTime() - date1.getTime();

    // Calculating the no. of days between two dates
    const diffInDays = Math.round(diffInTime / oneDay);

    return diffInDays;
}

export function getMonthStr(month){
  const array = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai',
    'Juin', 'Juillet', 'Aout', 'Septembre', 'Octobre', 'Novembre', 'Décembre'];
  return array[month]
}

export function getWeek(date){

  let startDate = new Date(date.getFullYear(), 0, 1);
  let days = Math.floor((date - startDate) /
      (24 * 60 * 60 * 1000));
  let weekNumber = Math.ceil(days / 7);

  return weekNumber;
}

export function getDayOfWeek(weekNbr){
  let nbrDay = weekNbr*7
  let today = new Date()
  let dayOfWeek = new Date(today.getFullYear(), 0, nbrDay);
  return dayOfWeek;
}

export function getWeekLabel(date){
  let dayNumber = date.getDay()
  let dateNumber = date.getDate()

  let firstDay = new Date(date.getFullYear(), date.getMonth(), dateNumber - (dayNumber - 2))
  let firstDayLabel = firstDay.toISOString().substr(0, 10);

  let lastDay = new Date(date.getFullYear(), date.getMonth(), dateNumber + 6 - (dayNumber - 2))
  let lastDayLabel = lastDay.toISOString().substr(0, 10);

  return [firstDayLabel, lastDayLabel];
}

export function getWeekLabelStr(date){
  let tmp = getWeekLabel(date);
  let begin = tmp[0].substr(5, 10);
  let end = tmp[1].substr(5, 10);
  return begin + " -> " + end
}
import Form from 'react-bootstrap/Form';
import React, {useEffect, useState} from "react";
import {base_url} from "../api_url/url";

function makeOptionList(data, keysOption){
  let list = []
  for (let i = 0; i < data.length; i++) {
      let value = ''
      for (let j = 0; j < keysOption.length; j++){
        if(j !== 0){
          value += ' '
        }
        value += data[i][keysOption[j]]
      }
      list.push({
        id: data[i].id,
        value: value
      })
  }
  return list;
}

export default function SelectData({defaultValue, keysOption, error, setValue, param, target, value}) {

  if(!keysOption){
    keysOption = ["name"]
  }

  const [optionList, setOptionList] = useState([])
  useEffect(() => {
    let url = base_url + target
    if (param) {
      const key = Object.keys(param)[0]
      const value = Object.values(param)[0]
      url += "?" + key + "=" + value
    }
    fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    },
    })
    .then(res => {
      if (res.ok){
        return res.json();
      }
      throw res;
    })
    .then(data =>  {
      setOptionList(makeOptionList(data, keysOption));
    })
  },[target]);

  const onChange = (e) => {
    setValue(e.target.value)
  }

  return (
    <Form.Select
       isInvalid={error}
       aria-label="Default select example"
       value={value}
       onChange={onChange}
    >
      {defaultValue && <option value="DEFAULT">{defaultValue}</option>}
      {optionList.map((option, index) => {
        return(
          <option key={index} value={option.id}>
            {option.value}
          </option>
        )
      })}
    </Form.Select>
  )
}


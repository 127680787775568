import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import {TableFooter, TablePagination} from "@mui/material";
import {visuallyHidden} from '@mui/utils';
import styled, {createGlobalStyle} from "styled-components";
import {formatDate} from "../../../components/utils/time/index"

const GlobalStyle = createGlobalStyle`
    p {
      margin: 0;
    }`
;

const StyledH3 = styled.h3`
  margin-left: 10px;
  margin-top: 5px 
`


function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}



function EnhancedTableHead(props) {
  const {order, orderBy, onRequestSort} = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {props.headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sx={{
              background:'#D6D6D6',
              fontSize: '14px',
              lineHeight: '16px',
              padding:"7px 16px"
            }}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  headCells: PropTypes.array.isRequired,
};

function countHours(data){
  let somme = 0;
   for(let i = 0; i < data.length; i++){
     if(!data[i].deleted && !data[i].updated){
       somme += data[i].time;
     }
  }
  return somme.toFixed(2);
}

export default function EnhancedTable(props) {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const totalHours = countHours(props.rows)

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleClick = (e, name) => {
    props.setShow(true);
    props.setModalId(name)
    console.log(name)
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  let height = "100px"
  return (
    <Paper sx={{ width: '100%', overflow: 'hidden', margin:'20px auto', }}>
      <TableContainer
        component={Paper}
      >
        <GlobalStyle/>
        <StyledH3>{props.title}</StyledH3>
        <Table
          stickyHeader
          aria-labelledby="simple table"
        >
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={props.rows.length}
            headCells={props.headCells}
          />
          <TableBody>
            {/* if you don't need to support IE11, you can replace the `stableSort` call with:
               rows.slice().sort(getComparator(order, orderBy)) */}
            {stableSort(props.rows, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, rowIndex) => {
                let id = rowIndex
                if(row.id){
                  id = row.id
                }
                let colored = "black";
                let decoration = "none";
                if (row["deleted"]) {
                  colored = "red";
                  decoration = "line-through";
                } else if (row["history_num"]) {
                  colored = "orange";
                }
                return (
                  <TableRow
                    hover
                    tabIndex={-1}
                    key={rowIndex}
                    onClick={(e) => handleClick(e, row.id)}
                  >
                    {props.headCells.map((headCell, index) => {
                      let content = row[headCell.id]
                      if(headCell.label === "Date"){
                        content = formatDate(content)
                      }
                      return(
                        <TableCell
                            key={rowIndex + "-" + index}
                            style={{
                              height: '20px',
                              maxWidth: headCell.width,
                              minWidth: headCell.width,
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              padding: "8px 16px",
                              color: colored,
                              textDecoration: decoration,
                            }}
                        >
                          {content}
                        </TableCell>
                      )}
                      )}
                        <TableCell
                          key="tool-cell"
                          style={{
                            height: '20px',
                            maxWidth: '40px',
                            whiteSpace: 'nowrap',
                          }}
                          >

                        </TableCell>
                  </TableRow>
                );
              })}
            <TableRow>
              <TableCell
                      align="right"
                      colSpan={2}
                      style={{
                        height: '30px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        padding: "12px 16px",
                      }}
                  >
                    Nombre d'heure total:
                  </TableCell>
                  <TableCell
                      align="left"
                  >
                    {totalHours}H
                  </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        {props.rows.length > 10 &&
        <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            colSpan={3}
            component="div"
            count={props.rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
        />
        }
      </TableContainer>

    </Paper>
  );
}

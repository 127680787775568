import {Route, Routes} from 'react-router-dom';
import Project from "./index";
import NewForm from "./NewForm";

export default function ProjectRouter(){

  return(
      <Routes>
        <Route exact path='/' element={<Project/>}/>
        <Route path='new/' element={<NewForm/>}/>
      </Routes>
  )
}

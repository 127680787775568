import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import { styled } from '@mui/material/styles';
import Col from 'react-bootstrap/Col'
import {useEffect, useState} from "react";
import React from "react"
import SelectData from "../../../components/Form/SelectForm";
import {base_url} from "../../../components/api_url/url";

const StyledContainer = styled(Container)`
  margin-top: 100px;
  margin-bottom: 25px;
  border-radius: 5px;
  border: 1px solid #DDD;
  padding: 20px;
  position: sticky;
  top: 0;
  z-index: 10;
  background: white;
`

export default function TimeSheet({setTrigger, trigger, service, entite, duplicateData}){

  const curr = new Date();
  const today = curr.toISOString().substr(0,10);
  const [date, setDate] = useState(today)
  const [name, setName] = useState('')
  const [time, setTime] = useState('')
  const [project, setProject] = useState('')
  const [category, setCategory] = useState('')
  const [errorName, setErrorName] = useState(false)
  const [errorTime, setErrorTime] = useState(false)
  const [errorProject, setErrorProject] = useState(false)
  const [errorCategory, setErrorCategory] = useState(false)

  const validate = () => {
    setErrorName(false)
    setErrorTime(false)
    setErrorProject(false)
    setErrorCategory(false)
    let status = true
    if (!name) {
      status = false
      setErrorName(true)
    }
    if (!time) {
      status = false
      setErrorTime(true)
    }
    if (!project) {
      status = false
      setErrorProject(true)
    }
    if (!category) {
      status = false
      setErrorCategory(true)
    }
    return status
  };

  useEffect(() => {
    if(duplicateData) {
      setDate(duplicateData.date)
      setProject(duplicateData.project)
      setCategory(duplicateData.category)
      setName(duplicateData.name)
      setTime(duplicateData.time)
    }
  },[duplicateData])

  const onSubmit = (e) => {
    e.preventDefault();
    const presta_data = {
      date: date,
      name: name,
      time: time,
      category: category,
      project: project,
    }
    if (validate()) {
      fetch(base_url + 'timesheet/personalPresta/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        body: JSON.stringify(presta_data)
      })
        .then(res => {
          if (res.ok) {
            return res.json();
          }
          throw res;
        })
        .then(() => {
          setTrigger(trigger + 1)
          setName("")
          setTime('')
          setProject('')
          setCategory('')
          setDate('')
        })
        .catch(error => {
          console.error("Error posting data Address: ", error);
          alert("il y a eu un problème dans l'envoie des donées")
        })
    }
  };

  return(
    <StyledContainer>
      <Form onSubmit={onSubmit}>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicPassword">
            <Form.Control
              type="date"
              value={date}
              onChange={e => setDate(e.target.value)}
            />
          </Form.Group>
           <Form.Group as={Col} controlId="formBasicPassword">
             {entite &&
               <SelectData
                   defaultValue="dossier"
                   error={errorProject}
                   value={project}
                   setValue={setProject}
                   target="project/projectList/"
                   param={entite && {entite: entite}}
               />
             }
          </Form.Group>
          <Form.Group as={Col} controlId="formBasicPassword">
            {service &&
              <SelectData
                  defaultValue="Categorie"
                  error={errorCategory}
                  value={category}
                  setValue={setCategory}
                  target="timesheet/categoryList/"
                  param={{service: service}}
              />
            }
          </Form.Group>
          <Form.Group as={Col} controlId="formBasicPassword">
            <Form.Control
                isInvalid={errorName}
                type="text"
                placeholder="Prestation"
                value={name}
                onChange={e => setName(e.target.value)}
                autocomplete="off"
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formBasicPassword">
            <Form.Control
                isInvalid={errorTime}
                type="text"
                placeholder="Temps (H)"
                value={time}
                onChange={e => setTime(e.target.value)}
                autocomplete="off"
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formBasicPassword">
            <Button variant="primary" type="submit">
              Enregister
            </Button>
          </Form.Group>

        </Row>
      </Form>
    </StyledContainer>
  )
}
import styled from "styled-components";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import React, {useEffect, useState} from "react";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import SelectData from "../../../components/Form/SelectForm";
import History from "./History";
import {base_url} from "../../../components/api_url/url";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


const StyledH4 = styled.h4`
  margin-top: 25px;
`

const StyledGroup = styled(Form.Group)`
  margin-bottom: 10px;
`

export default function ModalForm(props) {

  const handleClose = () => props.setShow(false);

  const [date, setDate] = useState('');
  const [name, setName] = useState('');
  const [time, setTime] = useState('');
  const [project, setProject] = useState('');
  const [category, setCategory] = useState('');
  const [histNum, setHistNum] = useState();
  const [errorName, setErrorName] = useState(false)
  const [errorTime, setErrorTime] = useState(false)
  const [errorProject, setErrorProject] = useState(false)
  const [errorCategory, setErrorCategory] = useState(false)

  const [entite, setEntite] = useState('')
  const [service, setService] = useState('')

  useEffect(() => {
    const url = base_url + 'user/userInfo/?user=-1'
    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      },
    })
      .then(res => {
        if (res.ok) {
          return res.json();
        }
        throw res;
      })
      .then(data => {
        setEntite(data.entite_id);
        setService(data.service_id);
      })
      .catch(error => {
        console.error("Error posting data Address: ", error);
      })
  }, []);

  const validate = () => {
    setErrorName(false)
    setErrorTime(false)
    setErrorProject(false)
    setErrorCategory(false)
    let status = true
    if (!name) {
      status = false
      setErrorName(true)
    }
    if (!time) {
      status = false
      setErrorTime(true)
    }
    if (!project) {
      status = false
      setErrorProject(true)
    }
    if (!category) {
      status = false
      setErrorCategory(true)
    }
    return status
  };

  const handleDuplicate = (e) => {
    e.preventDefault();
    props.setDuplicateData({
      "date":date,
      "project":project,
      "category":category,
      "name":name,
      "time":time,
    })
    props.setShow(false);
  }

  const onDelete = (e) => {
    e.preventDefault();
    let sure = window.confirm("êtes vous sûr de vouloir supprimer cette préstation");
    if(sure){
      const presta_data = {
        id: props.modalId
      }
      fetch(base_url + 'timesheet/personalPresta/', {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        body: JSON.stringify(presta_data)
      })
        .then(res => {
          if (res.ok) {
            return res.json();
          }
          throw res;
        })
        .then(() => {
          props.setTrigger(props.trigger + 1);
        })
        .catch(error => {
          console.error("Error posting data Address: ", error);
          alert("il y a eu un problème dans l'envoie des donées")
        })
    }
    props.setShow(false);
  }

  const onSubmit = (e) => {
    e.preventDefault();
    const presta_data = {
      id: props.modalId,
      date: date,
      name: name,
      time: time,
      category: category,
      project: project,
    }
      if (validate()) {
      fetch(base_url + 'timesheet/personalPresta/', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        body: JSON.stringify(presta_data)
      })
        .then(res => {
          if (res.ok) {
            return res.json();
          }
          throw res;
        })
        .then(() => {
          props.setTrigger(props.trigger + 1);
        })
        .catch(error => {
          console.error("Error posting data Address: ", error);
          alert("il y a eu un problème dans l'envoie des donées")
        })
      }
    props.setShow(false);
  };

  useEffect(() => {
    let url = base_url + 'timesheet/prestation/'
    if(props.modalId) {
      url += props.modalId + "/";
      fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
      })
        .then(res => {
          if (res.ok) {
            return res.json();
          }
          throw res;
        })
        .then(data => {
          setName(data.name);
          setCategory(data.category);
          setTime(data.time);
          setProject(data.project);
          setDate(data.date);
          setHistNum(data.history_num);
        })
    }
    console.log(props.modalId)
  }, [props.modalId]);

  return (
      <div>
        <Modal
          size="lg"
          show={props.show}
          onHide={handleClose}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
        <Modal.Header closeButton>
          <Modal.Title> {name} </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <StyledGroup as={Col}>
              <Form.Label>Date:</Form.Label>
              <Form.Control
                  type="date"
                  value={date}
                  onChange={e => setDate(e.target.value)}
              />
            </StyledGroup>
             <StyledGroup as={Col}>
               <Form.Label>Pdossier:</Form.Label>
               <SelectData
                 defaultId={project}
                 error={errorProject}
                 setValue={setProject}
                 target="project/projectList/"
                 param={{entite: entite}}
                 value={project}
              />
            </StyledGroup>
            <StyledGroup as={Col}>
              <Form.Label>Categorie:</Form.Label>
              <SelectData
                defaultId={category}
                error={errorCategory}
                setValue={setCategory}
                target="timesheet/categoryList/"
                param={{service: service}}
                value={category}
              />
            </StyledGroup>
            <StyledGroup as={Col}>
              <Form.Label>Nom:</Form.Label>
              <Form.Control
                  isInvalid={errorName}
                  type="text"
                  value={name}
                  placeholder="Préstation"
                  onChange={e => setName(e.target.value)}
              />
            </StyledGroup>
            <StyledGroup as={Col}>
              <Form.Label>Temps:</Form.Label>
              <Form.Control
                  isInvalid={errorTime}
                  value={time}
                  type="text"
                  placeholder="Temps (H)"
                  onChange={e => setTime(e.target.value)}
              />
            </StyledGroup>
          </Form>
          <StyledH4>Historique de modification:</StyledH4>
          <History
            title=""
            idModal={props.modalId}
            histNum={histNum}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fermer <FontAwesomeIcon icon="fas fa-times" />
          </Button>
          <Button variant="primary" onClick={onSubmit}>
            Enregister <FontAwesomeIcon icon="fas fa-save" />
          </Button>
           <Button variant="primary" onClick={handleDuplicate}>
            Dupliquer <FontAwesomeIcon icon="far fa-clone" />
          </Button>
          <Button variant="danger" onClick={onDelete}>
            Supprimer <FontAwesomeIcon icon="fas fa-trash" />
          </Button>
        </Modal.Footer>
        </Modal>
      </div>
  )
}

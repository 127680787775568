import styled from "styled-components";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const Styledp = styled.p`
  text-align: center;
  max-width: 150px;
  border-radius: 10px;
  margin:10px;
  color: white;
  font-size: 15px;
`

const StyledLink = styled(Link)`
  text-decoration: none;
  width: 150px;
  height: 150px;
  margin:10px;
`


const IconWarpper = styled.div`
  margin: 10px auto;
  font-size: 50px;
  color: white;
`

const StyledDiv = styled.div`
  border-radius: 10px;
  width: 150px;
  height: 150px;
  background: ${props => props.inputColor};
  display: flex;
  flex-direction: column;
  &:hover {
    transition-timing-function: linear;
    box-shadow: 10px 10px 10px #666;
    opacity: 0.6;
    transform: translate(-5px, -5px);
    transition: opacity 0.5s, box-shadow 0.5s, transform 0.5s;
  }
`
function AppButton({link, title, cover, inputColor}) {

  return (
    <StyledLink to={link}>
      <StyledDiv inputColor={inputColor}>
        <IconWarpper>
          <FontAwesomeIcon icon={cover} />
        </IconWarpper>
        <Styledp>
        {title}
        </Styledp>
      </StyledDiv>
    </StyledLink>
  )
}

export default AppButton
import {Fragment, useEffect, useState} from "react";
import { styled } from '@mui/material/styles';
import {base_url} from "../../../components/api_url/url";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import myStyled from "styled-components";
import {ListGroup} from "react-bootstrap";
import FilterToolBar from './FilterToolBar'
import Badge from 'react-bootstrap/Badge';

const StyledLink = styled(Link)`
    color: #FFFFFF;
    font-size: 17px;
    -webkit-text-decoration: none;
    text-decoration: none;
    background: #0096C7;
    border-radius: 29px;
    text-align: center;
    padding: 5px 20px 7px 15px;
    margin: 0px 0px;
    :hover {
      color: white;
      background: cadetblue;
    }
`
const PlusIcon = myStyled(FontAwesomeIcon)`
    margin-right: 5px;
    text-weight: bold;
  `


const ButtonWarpper = styled("div")`
  padding: 10px;
  text-align: center;
`

const StyledH3 = styled("h3")`
  margin: 10px;
  text-decoration: underline;
`

function groupByEntity(dataset) {
  let entityArray = []
  // detect the different week of the presentation
  for(let i=0; i<dataset.length; i++){
    let entity = dataset[i].entite
    if(!entityArray.includes(entity)){
      entityArray.push(entity);
    }
  }

  let data_list = new Array(entityArray.length);
  // initialise the 2D array
  for(let i=0; i<entityArray.length; i++){
    data_list[i] = [];
  }

  for(let i=0; i<dataset.length; i++){
    let entity = dataset[i].entite
    const index = entityArray.indexOf(entity);
    data_list[index].push(dataset[i])
  }

  return [data_list, entityArray];
}

function get_entity(id, entities) {
  for (let i = 0; entities.length; i++) {
      if(id === entities[i].id)
      return entities[i]
  }
}

export default function Index({setProject}) {

  const [dataset, setDataset] = useState(null);
  const [error, setError] = useState(false)
  const [activeItem, setActiveItem] = useState("")
  const [group, setGroup] = useState(false)
  const [status, setStatus] = useState('')
  const [entity, setEntity] = useState('');

  const [listEntite, setListEntite] = useState([]);

  useEffect(() => {
    fetch(base_url + 'entite/', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      },
    })
    .then(res => {
      if (res.ok){
        return res.json();
      }
      throw res;
    })
    .then(data =>  {
      setListEntite(data);
    })
    .catch(error => {
      console.error("Error fetching data: ", error);
      setError(true);
    })
  }, []);

  useEffect(() => {
    setError(false)
    let url = 'project/project/'
    let firstParam=true
    if(status && status !== "DEFAULT"){
      url += '?status=' + status
      firstParam=false
    }
    if(entity && entity !== "DEFAULT"){
      console.log(entity)
      if(firstParam){
        url += '?'
      } else {
        url += '&'
      }
      url += 'entity=' + entity
    }
    fetch(base_url + url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    },
    })
    .then(res => {
      if (res.ok){
        return res.json();
      }
      throw res;
    })
    .then(data =>  {
      setDataset(data);
    })
    .catch(error => {
      console.error("Error posting data project: ", error);
      setError(true);
    })
   }, [entity, status]) ;

  const ProjectList = [];
  let list_group = []
  if(dataset) {
    let tmp
    if (group) {
      tmp = groupByEntity(dataset)
    } else {
      tmp = [[dataset], ['']]
    }

    const data_list = tmp[0];
    list_group = tmp[1];
    // initialise the 2D array
    for (let i = 0; i < list_group.length; i++) {
      ProjectList.push([]);
    }
    for (let i = 0; i < list_group.length; i++) {
      for (let data of data_list[i]) {
        if (data.id === activeItem) {
          ProjectList[i].push(
              <ListGroup.Item
                  as="li"
                  key={data.id}
                  onClick={() => {
                    setActiveItem(data.id)
                    setProject(data.id)
                  }}
                  active
              >
                {data.name}
              </ListGroup.Item>
          )
        } else {
          ProjectList[i].push(
              <ListGroup.Item
                  as="li"
                  key={data.id}
                  onClick={() => {
                    setActiveItem(data.id)
                    setProject(data.id)
                  }}
              >
                {data.name}
              </ListGroup.Item>
          )
        }
      }
    }
  }

  return (
    <div>
      <ButtonWarpper>
        <StyledLink to="/Project/new"> <PlusIcon icon="fas fa-plus" /> nouveau dossier</StyledLink>
      </ButtonWarpper>
      <FilterToolBar
        group={group}
        setGroup={setGroup}
        setEntity={setEntity}
        setStatus={setStatus}
      />
      <ListGroup as="ul">
        {ProjectList &&
          ProjectList.map((projectEntity, idx) => {
            return(
              <Fragment>
                <StyledH3>{list_group[idx] && get_entity(list_group[idx], listEntite).name}</StyledH3>
                {projectEntity}
              </Fragment>
            )
          })
        }
      </ListGroup>
      {error &&
        <p> Erreur du chargement des données</p>
      }
    </div>
  );
}

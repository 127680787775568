import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import { styled } from '@mui/material/styles';
import {useEffect, useState} from "react";
import {disconnect} from "../../LoginPage/utility";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Modal from 'react-bootstrap/Modal';
import DividerLine from "../../../components/Divider/DividerLine";
import {base_url} from "../../../components/api_url/url";

const StyledContainer = styled(Container)`
  background: white;
  max-width: 560px;
  margin-top: 100px;
  border: solid 1px #BBB;
  padding: 30px;
  border-radius: 5px;
  box-shadow: 10px 10px 12px rgb(0 ,0 ,0 , 0.32)
`

const ButtonBar = styled('div')`
  display: flex;
  flex-direction : row;
  justify-content: space-between;
`

const EyeIcon = styled(FontAwesomeIcon)`
  margin-left: -30px;
  margin-top: auto;
  margin-bottom: auto;
`

const PasswordDiv = styled('div')`
  display: flex;
`

const ButtonWarpper = styled('div')`
  margin: 30px auto;
  width: 400px;
`

const StyledButton = styled('button')`
  width: 400px;
  border-radius: 5px;
  padding: 10px;
  border: none;
  background: #003391;  
  color: white;
  border-radius: 25px;
  font-weight: bold;
  text-decoration: none;
  &:hover{
    background:cadetblue;
    color: white;
  }
`

export default function NewForm(props) {
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [entite, setEntite] = useState('');
  const [service, setService] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("il y a eu un problème dans l'envoie des données")

  const [passwordVisible, setPasswordVisible] = useState(false);
  const [modalShow, setModalShow] = useState(false);

  const eyeClick = () => {
    setPasswordVisible(!passwordVisible)
  }

  function CheckPassword(inputtxt) {
    return inputtxt.match(/(?=.*?[a-z])(?=.*?[0-9])(?=.*?[A-Z])(?=.*?[#?!@$%^&*-+_]).{6,20}/)
  }

  const validateData = () => {
    if(!email || !firstName || !lastName){
      setError(true)
      setErrorMessage("Tous les champs du formulaire doivent être remplis")
      return false;
    }
    if(!entite || !service || !password || !confirmPassword){
      setError(true)
      setErrorMessage("Tous les champs du formulaire doivent être remplis")
      return false;
    }
    if(!CheckPassword(password)){
      setError(true)
      setErrorMessage(
          "le mot de passe doit contenir au moins un nombre," +
          " une majuscle et une minuscle et entre 6 et 20 caractères"
      )
      return false;
    }
    if(password !== confirmPassword){
      setError(true)
      setErrorMessage(
          "les mot de passe ne correspondent pas"
      )
      return false;
    }
    return true;
  }

  const handleDisconnect = () => {
    disconnect()
  }

  useEffect(() => {
    const url = base_url + 'user/userInfo/?user=-1'
    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      },
    })
      .then(res => {
        if (res.ok) {
          return res.json();
        }
        throw res;
      })
      .then(data => {
        setFirstName(data.firstName);
        setLastName(data.lastName);
        setEntite(data.entite);
        setService(data.service);
        setEmail(data.email);
      })
      .catch(error => {
        console.error("Error posting data Address: ", error);
        setError(true)
      })
  }, []);

  const onSubmit = e => {
    e.preventDefault();
    if(validateData()) {
      const password_data = {
        password: password
      }
      fetch(base_url + 'user/editPassword/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        body: JSON.stringify(password_data)
      })
        .then(res => {
          if (res.ok) {
            alert("le mot de passe à bien été modifié");
            setModalShow(false);
            return res.json();
          }
          throw res;
        })
        .catch(error => {
          console.error("Error posting data password: ", error);
          setError(true)
        })
    }
  }

  return (
      <StyledContainer>
        <Form.Group className="mb-3" controlId="Email">
          <Form.Label>Email: {email}</Form.Label>
          <Form.Text className="text-muted">
          </Form.Text>
        </Form.Group>
        <Form.Group className="mb-3" controlId="FirstName">
          <Form.Label>Prénom: {firstName}</Form.Label>
        </Form.Group>
        <Form.Group className="mb-3" controlId="LastName">
          <Form.Label>Nom: {lastName}</Form.Label>
        </Form.Group>
        <Form.Group className="mb-3" controlId="Entite">
          <Form.Label>Entite: {entite}</Form.Label>
        </Form.Group>
        <Form.Group className="mb-3" controlId="Service">
          <Form.Label>Service: {service}</Form.Label>
        </Form.Group>
        <ButtonWarpper>
        <StyledButton onClick={() => setModalShow(true)}>
          Changer de mot de passe <FontAwesomeIcon icon="fas fa-edit" />
        </StyledButton>
        </ButtonWarpper>
        <DividerLine/>
        <ButtonBar>
          <a href="/">
            <Button variant="secondary">
              Retour
            </Button>
          </a>
          <a href="/">
            <Button onClick={handleDisconnect} variant="danger">
              Déconnexion
            </Button>
          </a>
        </ButtonBar>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size={"lg"}
        aria-labelledby={"contained-modal-title-vcenter"}
        centered
      >
        <Form onSubmit={onSubmit}>
          <Modal.Header closeButton>
          </Modal.Header>
          <Modal.Body>
            <Form.Group className="mb-3" controlId="Password">
              <Form.Label>Mot de passe :</Form.Label>
              <PasswordDiv>
                <Form.Control
                    type={passwordVisible? 'text': 'password'}
                    required
                    placeholder="password"
                    onChange={e => setPassword(e.target.value)}
                />
                <EyeIcon onClick={eyeClick} icon={"fas fa-eye"}/>
              </PasswordDiv>
            </Form.Group>
            <Form.Group className="mb-3" controlId="ConfirmPassword">
              <Form.Label>Comfirmer le mot de passe :</Form.Label>
              <PasswordDiv>
                <Form.Control
                    type={passwordVisible? 'text': 'password'}
                    required
                    placeholder="password"
                    onChange={e => setConfirmPassword(e.target.value)}
                />
                <EyeIcon onClick={eyeClick} icon={"fas fa-eye"}/>
              </PasswordDiv>
            </Form.Group>
            <Form.Control.Feedback style={{ display: error ? "block" : "none" }} type="invalid">
              {errorMessage}
            </Form.Control.Feedback>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" type="submit">
              Enregister
            </Button>
            <Button onClick={() => setModalShow(false)} variant="secondary">
              Retour
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      </StyledContainer>

  );
}
import {styled} from "@mui/material/styles";

const Line = styled("div")`
  border-bottom: 1px solid #666;
  margin: 0 20px;
  margin-bottom: 25px;
`

export default function DividerLine(){
  return(
      <Line/>
  )
}

import {getWeek, getNumberOfDays} from "./Date"

export function dispatchByWeek(data){

  let list_week = [];
  // detect the different week of the presentation
  for(let i=0; i<data.length; i++){
    let curr_week = getWeek(new Date(data[i].date))
    if(!list_week.includes(curr_week)) {
      list_week.push(curr_week);
    }
  }

  let data_list = new Array(list_week.length);
  // initialise the 2D array
  for(let i=0; i<list_week.length; i++){
    data_list[i] = [];
  }

  for(let i=0; i<data.length; i++){
    let curr_week = getWeek(new Date(data[i].date))
    const index = list_week.indexOf(curr_week);
    data_list[index].push(data[i])
  }

  return [data_list, list_week];
}

export function dispatchByMonth(data){

  let list_month = []
  // detect the different month of the presentation
  for(let i=0; i<data.length; i++){
    let curr_month = new Date(data[i].date).getMonth()
    if(!list_month.includes(curr_month)) {
      list_month.push(curr_month);
    }
  }

  let data_list = new Array(list_month.length)
  // initialise the 2D array
  for(let i=0; i<list_month.length; i++){
    data_list[i] = [];
  }

  for(let i=0; i<data.length; i++){
    let curr_month = new Date(data[i].date).getMonth()
    const index = list_month.indexOf(curr_month);
    data_list[index].push(data[i])
  }

  return [data_list, list_month]
}

export function dispatchByDay(data){
  let list_date = []
  // detect the different day of the presentation
  for(let i=0; i<data.length; i++){
    let curr_date = new Date(data[i].date).toISOString().substr(0, 10);
    if(!list_date.includes(curr_date)) {
      list_date.push(curr_date);
    }
  }

  list_date.sort().reverse();

  let data_list = new Array(list_date.length)
  // initialise the 2D array
  for(let i=0; i<=list_date.length; i++){
    data_list[i] = [];
  }
  // dispatch the prestation in the corresponding day's array
  for(let i=0; i<data.length; i++){
    const index = list_date.indexOf(data[i].date);
    data_list[index].push(data[i])
  }

  return [data_list, list_date]
}
import {Route, Routes} from 'react-router-dom';
import NewForm from "./NewForm";
import AllUser from "./AllUser/AllUser";
import MyProfil from "./MyProfil/MyProfil"

export default function UserRouter(){

  return(
      <Routes>
        <Route exact path='/' element={<AllUser/>}/>
      </Routes>
  )
}

import CompactTable from "./CompactTable";
import React, {useEffect, useState} from "react";
import Moment from "react-moment";
import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const Arrow = styled(FontAwesomeIcon)`
  margin: 0 15px;
`


const defaultId = {
  date: 'date',
  name: 'name',
  time: 'time',
  category: 'category__name',
  project: 'project__name',
  created_at: 'created_at',
  id: 'id',
  deleted: 'deleted',
  history_num: 'history_num'
};

function makeHeadCells(columnsID){
  const headCells = [];
  headCells.push({id: 'date', label: 'Date', width: '50px'})
  if(columnsID && "user" in columnsID){
    headCells.push({id: 'user', label: 'Collaborateur',})
  }
  headCells.push(
    {
      id: 'name',
      label: 'Nom',
      width: '250px'
    },
    {
      id: 'time',
      label: 'Temps (H)',
      width: '60px'
    },
    {
      id: 'project',
      label: 'Dossier',
      width: '100px'
    },
    {
      id: 'category',
      label: 'Categorie',
      width: '80px'
    },
    {
      id: 'created_at',
      label: 'Ajouté à',
      width: '120px'
    },
  )
  return headCells;
}

function extractData(data, columnsID){
  let listId;
  if(columnsID){
    listId = columnsID;
  } else {
    listId = defaultId;
  }
  let rows = []
  for(let i = 0; i < data.length; i++){
    const d = data[i];
    if(!d.updated) {
      let userLabel  = ""
      if('user' in listId) {
        userLabel = d[listId.user].firstName + " " + d[listId.user].lastName;
      }
      rows.push({
        date: d[listId.date],
        user: userLabel,
        name: d[listId.name],
        time: d[listId.time],
        category: d[listId.category],
        project: d[listId.project],
        created_at: new Date(d[listId.created_at],).toLocaleString('fr-FR', {timeZone: 'UTC'}),
        id: d[listId.id],
        deleted: d[listId.deleted],
        history_num: d[listId.history_num]
      })
    }
  }
  return rows
}

export default function TimeTable({title, titleType, data, setShow, setModalId, columnsId}) {

  return (
      <CompactTable
          rows={extractData(data, columnsId)}
          headCells={makeHeadCells(columnsId)}
          title={formatedTitle(title, titleType)}
          width={"90%"}
          height={"500px"}
          setShow={setShow}
          setModalId={setModalId}
      />
  )
}

function formatedTitle(title, titleType) {
  switch (titleType) {
    case 'date':
      return (
          <Moment locale="fr" format="LL">{title}</Moment>
      )
    case 'week':
      let tmp = title.split(" ");
      return (
        <span>
          <Moment locale="fr" format="LL">{tmp[0]}</Moment>
          <Arrow icon="fas fa-arrow-right"/>
          <Moment locale="fr" format="LL">{tmp[1]}</Moment>
        </span>
      )
    default:
      return (title)
  }
}


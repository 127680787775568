import SimpleTable from "../../../components/Table/SimpleTable";
import {useEffect, useState} from "react";
import {base_url} from "../../../components/api_url/url";

function createData(firstName, lastName, time) {
  const title = firstName + " " + lastName;
  return {title, time};
}

function formatData(data){
  let rows = []
  for(let i=0; i<data.length; i++){
    const dataRow = data[i]
    rows.push(createData(
        dataRow.user__firstName,
        dataRow.user__lastName,
        dataRow.time__sum
        ))
  }
  return rows
}

const headCells = [
  {
    id: 'title',
    label: 'Nom',
  },
  {
    id: 'time',
    label: 'Temps',
  },
];

export default function WorkerTable({project}) {

  const [data, setData] = useState([]);

  useEffect(() => {
    if(project) {
      const url = base_url + 'timesheet/hoursByWorker/?project=' + project;
      fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
      })
        .then(res => {
          if (res.ok) {
            return res.json();
          }
          throw res;
        })
        .then(data => {
          const formatedData = formatData(data)
          setData(formatedData);
        })
        .catch(error => {
          console.error("Error posting data project: ", error);
        })
    }
  }, [project]) ;

  return(
    <SimpleTable
      rows={data}
      headCells={headCells}
    />
  )

}
import {base_url} from "../../components/api_url/url";

export function getPayload(jwt){
  // A JWT has 3 parts separated by '.'
  // The middle part is a base64 encoded JSON
  // decode the base64
  return atob(jwt.split(".")[1]).split(",")[1].split(":")[1]
}

export function disconnect(){
  localStorage.clear();
}

export function tokenIsExpired(){
  if(localStorage.getItem('tokenTimeout')) {
    const tokenTimout = new Date(localStorage.getItem('tokenTimeout'));
    const hoursLifetime = 12;
    const expiration = new Date(tokenTimout.getTime() + hoursLifetime*1000*3600);
    const remainingTime = expiration - new Date();
    return remainingTime > 0;
  } else {
    return false;
  }
}

export function checkValidity(jwt){
  if(!jwt){
    return false
  }
  const payload = parseInt(getPayload(jwt));
  const expiration = new Date(payload*1000);
  const now = new Date();
  const fiveMinutes = 1000 * 60 * 5;
  return expiration.getTime() - now.getTime() >= fiveMinutes;
}

export function refreshAccess(){
  console.log("refreshing acces")
  if(localStorage.getItem('token') && !checkValidity(localStorage.getItem('token'))){
    return(
    fetch(base_url + 'token/refresh/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({refresh: localStorage.getItem('refresh')})
    })
      .then(res => {
       if (res.ok) {
         return res.json();
       }
       throw res;
      })
      .then(data =>{
        localStorage.setItem('token', data.access)
      })
    )
  }
}


import styled from "styled-components";
import {Form, Row, Col} from 'react-bootstrap';
import React, {Fragment, useEffect, useState} from "react";
import Button from "react-bootstrap/Button";
import {InputGroup} from "react-bootstrap";
import SelectData from "../../../components/Form/SelectForm";
import Separator from "../../../components/Divider/Separator";

const StyledDiv = styled.div`
  margin-top: 25px;
  background: white;
  border: 1px solid #DDD;
  border-radius: 5px;
  padding: 5px;
`

const TopDiv = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  justify-content: space-around;
`

const StyledH4 = styled.h4`
  width: 125px;
`

const SelectorDiv = styled.div`
`

const Selector = styled.button`
  margin: 0 5px;
  border-radius: 20px;
  background: #5c636a;
  padding: 2px 10px;
  border: none;
  color: white;
  &:hover{
    background: #D6D6D6;
  }
`

const StyledLabel = styled.h4`
  margin: 0 10px;
  font-size: 20px;
`

function getPreviousWeekDate(week){
  const today = new Date();
  const isoToday = today.toISOString().substr(0, 10);
  const timeValue = today.setDate(today.getDate() - 7*week);
  const isoDate = new Date(timeValue).toISOString().substr(0, 10);
  return [isoToday, isoDate];
}

function getPreviousMonthDate(month){
  const today = new Date();
  const isoToday = today.toISOString().substr(0, 10);
  const timeValue = today.setMonth(today.getMonth() - month);
  const isoDate = new Date(timeValue).toISOString().substr(0, 10);
  return [isoToday, isoDate];
}

export default function TableToolBar(props){

  const [groupBy, setGroupBy] = useState('Default')

  const previousMonth = () => {
    const dates = getPreviousMonthDate(1)
    props.setEndDate(dates[0]);
    props.setStartDate(dates[1]);
  }

  const previous3Month = () => {
    const dates = getPreviousMonthDate(3)
    props.setEndDate(dates[0]);
    props.setStartDate(dates[1]);
  }

  const previous6Month = () => {
    const dates = getPreviousMonthDate(6)
    props.setEndDate(dates[0]);
    props.setStartDate(dates[1]);
  }

  const previousWeek = () => {
    const dates = getPreviousWeekDate(1)
    props.setEndDate(dates[0]);
    props.setStartDate(dates[1]);
  }

  const previous2Week = () => {
    const dates = getPreviousWeekDate(2)
    props.setEndDate(dates[0]);
    props.setStartDate(dates[1]);
  }

  const onChange = (e) => {
    e.preventDefault();
    props.setType(e.target.value);
    setGroupBy(e.target.value)
    props.setTrigger(e.target.value);
  };

  const clearFilter = (e) =>{
    e.reset();
    props.setType("Default");
    setGroupBy("Default")
    props.setStartDate("");
    props.setEndDate("");
  }

  useEffect(() => {
    if(!props.startDate && !props.endDate){
      previousWeek()
    }
  })

  return(

    <StyledDiv>
      <TopDiv>
        <StyledH4>Filtres :</StyledH4>
        <SelectorDiv>
          <Selector onClick={previousMonth}>1 mois</Selector>
          <Selector onClick={previous3Month}>3 mois</Selector>
          <Selector onClick={previous6Month}>6 mois</Selector>
          <Selector onClick={previousWeek}>1 semaine</Selector>
          <Selector onClick={previous2Week}>2 semaine</Selector>
        </SelectorDiv>
        <div>
          <Button variant="secondary" onClick={clearFilter}>
          Restaurer
          </Button>
        </div>
      </TopDiv>
      {props.level === 1 &&
      <Fragment>
        <Separator/>
        <Row>
          <InputGroup as={Col}>
            <InputGroup.Text >Entite</InputGroup.Text>
            <SelectData
            defaultValue="------"
            setValue={props.setEntite}
            value={props.entite}
            target="entite/"
            />
          </InputGroup>
          <InputGroup as={Col}>
            <InputGroup.Text >Service</InputGroup.Text>
            <SelectData
            defaultValue="------"
            setValue={props.setService}
            service={props.entite}
            target="service/"
            />
          </InputGroup>
           <InputGroup as={Col}>
            <InputGroup.Text >Collaborateur</InputGroup.Text>
            <SelectData
            defaultValue="------"
            target="user/userList/"
            setValue={props.setWorker}
            value={props.worker}
            keysOption={["firstName", "lastName"]}
            />
          </InputGroup>
        </Row>
      </Fragment>
      }
      <Separator/>
      <Row>
        <InputGroup as={Col}>
          <InputGroup.Text >Dossier</InputGroup.Text>
          <SelectData
               defaultValue="------"
               setValue={props.setProject}
               value={props.project}
               target="project/projectList/"
               param={props.entite && {entite: props.entite}}
           />
        </InputGroup>
        <InputGroup as={Col}>
          <InputGroup.Text >Categorie</InputGroup.Text>
          <SelectData
               defaultValue="------"
               setValue={props.setCategory}
               value={props.category}
               target="timesheet/categoryList/"
               param={props.service && {service: props.service}}
           />
        </InputGroup>
        {props.level === 2 &&
          <InputGroup as={Col}>
          <InputGroup.Text >Collaborateur</InputGroup.Text>
          <SelectData
            defaultValue="------"
            target="user/userInfoList/"
            value={props.worker}
            setValue={props.setWorker}
            keysOption={["firstName", "lastName"]}
            />
          </InputGroup>
        }
      </Row>
      <Separator/>
      <Row>
        <InputGroup as={Col}>
          <InputGroup.Text id="basic-addon1">Grouper par:</InputGroup.Text>
          <Form.Select aria-label="GroupBy" value={groupBy} onChange={onChange}>
            <option value="Default">------</option>
            <option value="day">jour</option>
            <option value="week">semaine</option>
            <option value="month">mois</option>
            {props.level === 1 ?
              <Fragment>
                <option value="category">catégorie</option>
                <option value="project">dossier</option>
              </Fragment>:
              <Fragment>
                <option value="category__name">catégorie</option>
                <option value="project__name">dossier</option>
              </Fragment>
            }
          </Form.Select>
        </InputGroup >
        <InputGroup as={Col} >
          <InputGroup.Text >du:</InputGroup.Text>
          <Form.Control
            type="date"
            value={props.startDate}
            onChange={e => props.setStartDate(e.target.value)}
          />
        </InputGroup>
        <InputGroup as={Col}>
          <InputGroup.Text >au:</InputGroup.Text>
          <Form.Control
            type="date"
            value={props.endDate}
            onChange={e => props.setEndDate(e.target.value)}
          />
        </InputGroup>
      </Row>
    </StyledDiv>
  )
}
import Header from "./components/Header";
import {base_url} from "./components/api_url/url";
import {BrowserRouter as Router, Route, Routes, Navigate} from "react-router-dom";
import Home from "./pages/Home";
import LoginPage from "./pages/LoginPage";
import ProjectRouter from "./pages/Project/Router";
import AllPresta from "./pages/Presta/AllPresta"
import MyService from "./pages/Presta/MyService"
import TimeSheet from "./pages/TimeSheet";
import MyProfil from "./pages/User/MyProfil/MyProfil"
import React, {Fragment} from "react";
import {createGlobalStyle} from "styled-components";
import UserRouter from "./pages/User/Router";
import Category from "./pages/Category";
import {useState, useEffect} from "react";
import {tokenIsExpired, refreshAccess} from "./pages/LoginPage/utility";

const GlobalStyle = createGlobalStyle`
    body {
      font-family: 'Trebuchet MS', Helvetica, sans-serif;
      background: #F6F6F6;
    }`;

export default function Urls() {
  const [levelAccess, setLevelAccess] = useState(-1)
  const [entiteUser, setEntiteUser] = useState("")
  const [trigger, setTrigger] = useState(0)

  useEffect(() => {
    const timer = setInterval(() => {
      refreshAccess();
    }, 60000);
               // clearing interval
    return () => clearInterval(timer);
  });

  useEffect(() => {
    if(tokenIsExpired()) {
      fetch(base_url + 'user/getLevelAccess/', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
      })
          .then(res => {
            if (res.ok) {
              return res.json();
            }
            throw res;
          })
          .then(data => {
            setLevelAccess(data.level)
            setEntiteUser(data.entite)
          })
          .catch(error => {
            console.error("Error fetching level access: ", error);
          })
    } else {
      setLevelAccess(4)
    }
  }, [trigger])

  return(
  <div>
    <Router>
      <GlobalStyle/>
      <Header levelAccess={levelAccess} entite={entiteUser}/>
      <Routes>
        {levelAccess <= 3 ?
            <Route
                exact path='/'
                element={<Home levelAccess={levelAccess}/>}
            />:
            <Route
                exact path='/'
                element={<LoginPage trigger={trigger} setTrigger={setTrigger}/>}
            />
        }
        <Route
            path='Login'
            element={<LoginPage trigger={trigger} setTrigger={setTrigger}/>}
        />
        <Route
            exact path='Worker/myprofil'
            element={<MyProfil/>}
        />

        {levelAccess <= 3 &&
          <Route
              path='TimeSheet/*'
              element={<TimeSheet/>}
          />
        }
        {levelAccess <= 2 &&
          <Route
              path='MyService/*'
              element={<MyService/>}
          />
        }
        {levelAccess === 1 &&
          <Fragment>
            <Route
                path='AllPresta/*'
                element={<AllPresta/>}
            />
            <Route
                path='Project/*'
                element={<ProjectRouter/>}
            />
            <Route
              path='Category/*'
              element={<Category/>}
            />

            <Route
                path='Worker/*'
                element={<UserRouter/>}
            />
          </Fragment>
        }
      </Routes>
    </Router>
  </div>
  )
}
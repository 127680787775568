export function dispatchData(data, key){
  console.log(data)
  let list_cat = []
  for(let i=0; i<data.length; i++){
    if(!list_cat.includes(data[i][key])){
      list_cat.push(data[i][key])
    }
  }
  let data_list = new Array(list_cat.length)
  for(let i=0; i<=list_cat.length; i++){
    data_list[i] = [];
  }
  for(let i=0; i<data.length; i++){
    const index = list_cat.indexOf(data[i][key]);
    data_list[index].push(data[i])
  }
  return [data_list, list_cat]
}
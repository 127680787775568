import styled from "styled-components";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import React, {Fragment, useEffect, useState} from "react";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import SelectData from "../../components/Form/SelectForm";
import History from "../TimeSheet/Form/History";
import {base_url} from "../../components/api_url/url";

const StyledDiv = styled.div`
  margin-top: 25px
  
`

const StyledH4 = styled.h4`
  margin-top: 25px;
`

const StyledGroup = styled(Form.Group)`
  margin-bottom: 10px;
`

export default function ModalForm(props) {

  const handleClose = () => props.setShow(false);

  const [date, setDate] = useState('');
  const [name, setName] = useState('');
  const [time, setTime] = useState('');
  const [project, setProject] = useState('');
  const [category, setCategory] = useState('');
  const [histNum, setHistNum] = useState();

  useEffect(() => {
    let url = base_url + 'timesheet/prestation/'
    if(props.modalId) {
      url += props.modalId + "/";
      fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
      })
        .then(res => {
          if (res.ok) {
            return res.json();
          }
          throw res;
        })
        .then(data => {
          setName(data.name);
          setCategory(data.category);
          setTime(data.time);
          setProject(data.project);
          setDate(data.date);
          setHistNum(data.history_num);
        })
    }
  }, [props.modalId]);

  if(name){
    return (
        <StyledDiv>
          <Modal show={props.show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title> {name} </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h5>Date: {date}</h5>
            <h5>Dossier: {project}</h5>
            <h5>Categorie: {category}</h5>
            <h5>Nom: {name}</h5>
            <h5>Temps: {time.toFixed(2)}H</h5>
            {histNum &&
              <Fragment>
                <StyledH4>Historique de modification:</StyledH4>
                <History
                title=""
                idModal={props.modalId}
                histNum={histNum}
                />
              </Fragment>
            }
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Fermer
            </Button>
          </Modal.Footer>
          </Modal>
        </StyledDiv>
    )}
  else{
    return ('')
  }
}

export const colorList = [
	{
		code: "#f94144",
	},
	{
		code: "#f3722c"
	},
	{
		code: "#f9c74f",
	},
	{
		code: "#90be6d",
	},
	{
		code: "#43aa8b"
	},
	{
		code: "#003391"
	}

]

export const AppList = [
	{
		name:"Prestations",
		url:"TimeSheet",
		cover:"fas fa-clock",
		level:3
	},
	// {
	// 	name:"Wiki",
	// 	url:"Wiki",
	// 	cover:"fas fa-question-circle",
	// },
	// {
	// 	name:"Taches",
	// 	url:"Tasks",
	// 	cover:"fas fa-tasks",
	// },
	{
		name:"Dossiers",
		url:"Project",
		cover:"fas fa-building",
		level:1
	},
	// {
	// 	name:"Suivi Facture",
	// 	url:"Facture",
	// 	cover:"fas fa-file-invoice-dollar",
	// },
	// {
	// 	name:"Test github",
	// 	url:"Facture",
	// 	cover:"fas fa-file-invoice-dollar",
	// },
	{
		name:"Collaborateurs",
		url:"Worker",
		cover:"fas fa-user",
		level:1
	},
]
import styled from "styled-components";
import {useState} from "react";
import React from "react";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import {Col, Form, ToggleButton} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const StyledP = styled.p`
  margin: 0 10px;
`

const StyledDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding-bottom: 15px;
  border-bottom: 1px solid black;
`

const DateGroup = styled.div`
  display: flex;
  flex-direction: row;
`

export default function ChartToolBar(props){

  const radiosType = [
    { name: 'RadioType', value: 'stackedBar', label: 'Bâtonnets'},
    { name: 'RadioType', value: 'pie', label: 'Secteurs'},
  ];

  const radios_x_value = [
    { name: 'RadioGroupBy', value: 'day', label: 'jour'},
    { name: 'RadioGroupBy', value: 'week', label: 'semaine'},
    { name: 'RadioGroupBy', value: 'month', label: 'mois'},
  ];

  const radiosGroup = [
    { name: 'RadioGroup', value: 'project', label: 'Dossier'},
    { name: 'RadioGroup', value: 'category', label: 'Categorie'},
  ];

  const handleTypeChange = (e) => {
    e.preventDefault();
    props.setType(e.currentTarget.value)
    props.setGroup('')
  }

  return(
    <StyledDiv>
      <ButtonGroup>
        {radiosType.map((radio, idx) => (
          <ToggleButton
            key={idx}
            id={`radioType-${idx}`}
            type="radio"
            variant= 'outline-primary'
            name={radio.name}
            value={radio.value}
            checked={props.type === radio.value}
            onChange={handleTypeChange}
          >
            {radio.label}
          </ToggleButton>
        ))}
      </ButtonGroup>
      {props.type === 'stackedBar' &&
        <ButtonGroup>
        {radios_x_value.map((radio, idx) => (
          <ToggleButton
            key={idx}
            id={`radioGroupBy-${idx}`}
            type="radio"
            variant= 'outline-secondary'
            name={radio.name}
            value={radio.value}
            checked={props.x_value === radio.value}
            onChange={(e) => props.setX_value(e.currentTarget.value)}
          >
            {radio.label}
          </ToggleButton>
        ))}
        </ButtonGroup>
      }
      <DateGroup>
        <StyledP>Du:</StyledP>
        <Form.Control
          type="date"
          value={props.startDate}
          onChange={e => props.setStartDate(e.target.value)}
        />
        <StyledP>Au:</StyledP>
        <Form.Control
          type="date"
          value={props.endDate}
          onChange={e => props.setEndDate(e.target.value)}
        />
      </DateGroup>
      <ButtonGroup>
        {radiosGroup.map((radio, idx) => (
          <ToggleButton
            key={idx}
            id={`radioGroup-${idx}`}
            type="radio"
            variant= 'outline-secondary'
            name={radio.name}
            value={radio.value}
            checked={props.group === radio.value}
            onChange={(e) => props.setGroup(e.currentTarget.value)}
          >
            {radio.label}
          </ToggleButton>
        ))}
      </ButtonGroup>
    </StyledDiv>
)
}
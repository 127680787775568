import {useState} from "react";
import * as React from "react";
import TimeTable from "../TimeTable";
import {dispatchData} from "../utils/TablesByKey";
import ModalForm from "../Form/ModalForm";
import {base_url} from "../../../components/api_url/url";
import ModalInfo from "../../Presta/ModalInfo";

export default function TableByKey(props) {
  const {trigger, setTrigger, type, startDate, endDate} = props
  const [modalId, setModalId] = useState();
  const [tables, setTables] = useState([])
  const [show, setShow] = useState(false);

  React.useEffect(() => {
    let url;
    if(props.level === 1) {
      url = base_url + 'timesheet/prestationList/'
    } else {
      url = base_url + 'timesheet/personalPresta/'
    }

    let end_date;
    let start_date;

    if(startDate && endDate){
      end_date = new Date(endDate);
      start_date = new Date(startDate);
    } else {
      end_date = new Date();
      start_date = new Date();
      start_date.setDate(end_date.getDate() - 6);

    }

    url += "?end_date=" + end_date.toISOString().substr(0, 10);
    url += "&start_date=" + start_date.toISOString().substr(0, 10);

    if(props.project && props.project !== "DEFAULT"){
      url += "&project=" + props.project;
    }

    if(props.category && props.category !== "DEFAULT"){
      url += "&category=" + props.category;
    }

    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      },
    })
      .then(res => {
        if (res.ok) {
          return res.json();
        }
        throw res;
      })
      .then(data => {
        console.log(type)
        const tmp = dispatchData(data, type)
        const data_list = tmp[0]
        const list_cat = tmp[1]

        let tableByDay = []
        for (let i = 0; i < list_cat.length; i++) {
          tableByDay.push(
            <TimeTable
              key={i}
              title={list_cat[i]}
              setShow={setShow}
              show={show}
              data={data_list[i]}
              setModalId={setModalId}
              columnsId={props.columnsId}
            />
          )
        }
        setTables(tableByDay)
      })
  }, [trigger, startDate, endDate]);


  return(
      <div>
        {tables}
        {props.level === 1 ?
            <ModalInfo
                show={show}
                setShow={setShow}
                modalId={modalId}
                trigger={trigger}
                setTrigger={setTrigger}
            /> :
            <ModalForm
                show={show}
                setShow={setShow}
                modalId={modalId}
                trigger={trigger}
                setDuplicateData = {props.setDuplicateData}
                setTrigger={setTrigger}
            />
        }
      </div>
  )
}
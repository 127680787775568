import {useEffect, useState} from "react";
import ChartToolBar from "./ToolBar/ChartToolBar";
import * as React from "react";
import MyPieChart from "./Charts/MyPieChart";
import StackedBar from "./Charts/StackedBar";



export default function ChartModule(){
  const [trigger, setTrigger] = useState(0)
  const [type, setType] = useState('')
  const [group, setGroup] = useState('')
  const [x_value, setX_value] = useState('day')
  const [chart, setChart] = useState([])
  let today = new Date();
  let tmp = new Date();
  tmp.setDate(today.getDate() - 6)
  const [startDate, setStartDate] = useState(tmp.toISOString().substr(0, 10));
  const [endDate, setEndDate] = useState(today.toISOString().substr(0, 10));

  let key = 1;

  useEffect(() => {
    key += 1;
    switch(type){
      case 'pie':
        setChart([
          <MyPieChart
            trigger={key}
            startDate={startDate}
            endDate={endDate}
            group={group}
          />]);
        break;
      case 'stackedBar':
        setChart([
          <StackedBar
            key={key}
            startDate={startDate}
            endDate={endDate}
            trigger={trigger}
            x_value={x_value}
            type={group}
          />]);
        break;
    }
  }, [type, group, startDate, endDate, x_value]);


  return(
    <div>
      <ChartToolBar
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        trigger={trigger}
        setTrigger={setTrigger}
        type={type}
        setType={setType}
        group={group}
        setGroup={setGroup}
        x_value={x_value}
        setX_value={setX_value}
      />
      {chart}
    </div>
  )
}
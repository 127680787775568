import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button'
import { styled } from '@mui/material/styles';
import {useEffect, useState} from "react";
import SelectData from "../../components/Form/SelectForm";
import Modal from 'react-bootstrap/Modal';
import {base_url} from "../../components/api_url/url";


const ButtonBar = styled('div')`
  display: flex;
  flex-direction : row;
  justify-content: space-between;
`

export default function CategoryNew(props) {
  const [name, setName] = useState('');
  const [order, setOrder] = useState('');
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("il y a eu un problème dans l'envoie des données")

  useEffect(() => {
    setName("");
  }, [props.show]);


  const onSubmit = e => {
    e.preventDefault();
    const category_data = {
      name: name,
      order: order,
      service: props.currService,
    }
    fetch(base_url + 'timesheet/category/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      },
      body: JSON.stringify(category_data)
    })
    .then(res => {
      if (res.ok) {
        alert("la catégorie a bien été crée");
        props.setTrigger(props.trigger + 1)
        props.setShow(false)
        return res.json();
      }
      throw res;
    })
    .catch(error => {
      console.error("Error posting data user: ", error);
      setError(true)
    })
  }

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size={"sm"}
      aria-labelledby={"contained-modal-title-vcenter"}
      centered
    >
      <Form onSubmit={onSubmit}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Créer une nouvelle catégorie
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3" controlId="LastName">
            <Form.Label>Nom :</Form.Label>
            <Form.Control
                type="text"
                required
                placeholder="Nom"
                onChange={e => setName(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="LastName">
            <Form.Label>Ordre :</Form.Label>
            <Form.Control
                type="text"
                required
                placeholder="Ordre"
                onChange={e => setOrder(e.target.value)}
            />
          </Form.Group>
          <Form.Control.Feedback style={{ display: error ? "block" : "none" }} type="invalid">
            {errorMessage}
          </Form.Control.Feedback>
        </Modal.Body>
        <Modal.Footer>
          <ButtonBar>
            <Button variant="primary" type="submit">
              Enregister
            </Button>
            <Button variant="secondary">
              Retour
            </Button>
          </ButtonBar>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

import { styled } from '@mui/material/styles';
import {Link} from "react-router-dom";
const StyledLink = styled(Link)`
    color: #FFFFFF;
    font-size: 15px;
    text-decoration: none;
    background: #023E8A;
    border-radius: 29px;
    text-align: center;
    padding: 4px 30px;
    margin: auto;
    margin-right: 50px;
    :hover {
      color: white;
      background: cadetblue;
    }
`


function CustomButton({link, title, className}) {

  return (
    <StyledLink to={link} className={className}>
      {title}
    </StyledLink>
  )
}

export default CustomButton
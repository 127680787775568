import SimpleTable from "../../../components/Table/SimpleTable";
import {useEffect, useState} from "react";
import {base_url} from "../../../components/api_url/url";

function createData(name, service, time) {
  return { name, service, time};
}

function formatData(data){
  let rows = []
  for(let i=0; i<data.length; i++){
    const dataRow = data[i]
    rows.push(createData(
        dataRow.category__name,
        dataRow.category__service__name,
        dataRow.time__sum
        ))
  }
  return rows
}

const headCells = [
  {
    id: 'name',
    label: 'NOM',
  },
  {
    id: 'service',
    label: 'SERVICE',
  },
  {
    id: 'time',
    label: 'TEMPS(H)',
  },
];

export default function WorkerCategoryTable({project, worker}) {

  const [data, setData] = useState([]);

  useEffect(() => {
    if(project && worker !== 'DEFAULT') {
      let url = base_url + 'timesheet/hoursByCat/?project=' + project;
      url += "&worker=" + worker;
      fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
      })
        .then(res => {
          if (res.ok) {
            return res.json();
          }
          throw res;
        })
        .then(data => {
          const formatedData = formatData(data)
          setData(formatedData);
        })
        .catch(error => {
          console.error("Error posting data project: ", error);
        })
    }
    else {
      setData([])
    }
  }, [project, worker]) ;

  return(
      <SimpleTable
          rows={data}
          headCells={headCells}
      />
  )

}
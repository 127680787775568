import {useEffect, useState} from "react";
import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Tab from "react-bootstrap/Tab"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import {base_url} from "../../components/api_url/url";
import {ListGroup} from "react-bootstrap";
import CategoryNew from "./ModalNew"
import CategoryEdit from "./ModalEdit";


const MiniPage = styled.div`
  max-width: 1000px;
  margin: 30px auto;
  padding: 30px;
`

const ListHeader = styled(ListGroup.Item)`
  font-size: 18px;
  font-weight: bold;
  text-decoration: underline;
`

const Button = styled.button`
  width: 100%;
  border-radius: 5px;
  font-size: 15px;
  padding: 5px;
  border: none;
  background: #003391;  
  color: white;
  border-radius: 25px;
  font-weight: bold;
  text-decoration: none;
  &:hover{
    background:cadetblue;
    color: white;
  }
`

const ButtonWarpper = styled.div`
  margin: 5px auto;
  width: 100%;
`

const StyledIcon = styled(FontAwesomeIcon)`
  &:hover{
    color: cadetblue;
  }
`

const categoryTest = [
    {name: "Design"},
    {name: "Develloppement"},
    {name: "administration system"}
]

function dispatchCategory(data){
  let listService = [];
  // detect the different service of the category
  for(let i=0; i<data.length; i++){
    if(!listService.includes(data[i].service)) {
      listService.push(data[i].service);
    }
  }

  let categoryList = {};
  for(let i=0; i<listService.length; i++){
    categoryList[listService[i]] = [];
  }

  for(let i=0; i<data.length; i++){
    categoryList[data[i].service].push(data[i])
  }

  for(let i=0; i<listService.length; i++){
    categoryList[listService[i]].sort()
  }

  return categoryList;
}

export default function Category() {

   const serviceArray = [];
   const TabArray = [];
   const [show, setShow] = useState(false)
   const [serviceData, setServiceData] = useState('');
   const [categoryList, setCategoryList] = useState('');
   const [currService, setCurrService] = useState('');
   const [trigger, setTrigger] = useState(0);
   const [showEdit, setShowEdit] = useState(false);
   const [modalId, setModalId] = useState('')

  const handleEdit = (e, name) => {
    setShowEdit(true);
    setModalId(name)
  }

  const handleClick = (service_id) => {
    setCurrService(service_id)
  }

   useEffect(() => {
      fetch(base_url + 'service/', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      },
      })
      .then(res => {
        if (res.ok){
          return res.json();
        }
        throw res;
      })
      .then(data =>  {
        setServiceData(data)
      })
      .catch(error => {
        console.error("Error fetching service List: ", error);
      })
      fetch( base_url + 'timesheet/category/', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      },
      })
      .then(res => {
        if (res.ok){
          return res.json();
        }
        throw res;
      })
      .then(rawData =>  {
        const data = dispatchCategory(rawData)
        setCategoryList(data)
      })
      .catch(error => {
        console.error("Error fetching service List: ", error);
      })
   }, [trigger]) ;

    for(let i=0; i<serviceData.length; i++) {
      const id = '#link' + i
      serviceArray.push(
          <ListGroup.Item action onClick={() => handleClick(serviceData[i].id)} key={serviceData[i].id} href={id}>
            {serviceData[i].name}
          </ListGroup.Item>
      )
    }

    for(let i=0; i<serviceData.length; i++) {
      const itemArray = []
      let length;
      if(categoryList[serviceData[i].id]){
        length = categoryList[serviceData[i].id].length;
      } else {
        length = 0;
      }
      for(let j=0; j<length; j++){
        itemArray.push(
        <ListGroup.Item className="d-flex justify-content-between " key={j}>
          {categoryList[serviceData[i].id][j].order}. {categoryList[serviceData[i].id][j].name}
          <StyledIcon onClick={(e) => handleEdit(e, categoryList[serviceData[i].id][j].id)} icon={"fas fa-edit"}/>
        </ListGroup.Item>)
      }
      const id = '#link' + i
      TabArray.push(
      <Tab.Pane eventKey={id} key={i}>
          {itemArray}
      </Tab.Pane>
      )
    }

  return (
    <MiniPage>
      <Tab.Container id="list-group-tabs-example" defaultActiveKey="#link1">
        <Row>
          <Col sm={5}>
            <ListGroup>
              <ListHeader> Service :</ListHeader>
              {serviceArray}
            </ListGroup>
          </Col>
          <Col sm={{span:5, offset:2}}>
            <ListGroup>
              <ListHeader> Category :</ListHeader>
              <Tab.Content>
                {TabArray}
              </Tab.Content>
              <ListGroup.Item>
              <ButtonWarpper>
                <Button onClick={() => setShow(true)}>
                  Nouvelle catégorie <FontAwesomeIcon icon="fas fa-plus" />
                </Button>
              </ButtonWarpper>
              </ListGroup.Item>
            </ListGroup>
          </Col>
        </Row>
      </Tab.Container>
      <CategoryNew
        onHide={() => setShow(false)}
        show={show}
        setShow={setShow}
        trigger={trigger}
        setTrigger={setTrigger}
        currService={currService}
      />
      <CategoryEdit
        onHide={() => setShowEdit(false)}
        modalId = {modalId}
        show={showEdit}
        setShow={setShowEdit}
        trigger={trigger}
        setTrigger={setTrigger}
        currService={currService}
      />
    </MiniPage>
  )
}
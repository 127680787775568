import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import {base_url} from "../../components/api_url/url";
import { styled } from '@mui/material/styles';
import {useEffect, useState} from "react";
import SelectData from "../../components/Form/SelectForm";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Modal from 'react-bootstrap/Modal';


const StyledContainer = styled(Container)`
  background: white;
  max-width: 560px;
  margin-top: 100px;
  border: solid 1px #BBB;
  padding: 30px;
  border-radius: 5px;
  box-shadow: 10px 10px 12px rgb(0 ,0 ,0 , 0.32)
`

const ButtonBar = styled('div')`
  display: flex;
  flex-direction : row;
  justify-content: space-between;
`

const EyeIcon = styled(FontAwesomeIcon)`
  margin-left: -30px;
  margin-top: auto;
  margin-bottom: auto;
`

const PasswordDiv = styled('div')`
  display: flex;
`


export default function NewForm(props) {
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [entite, setEntite] = useState('');
  const [service, setService] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("il y a eu un problème dans l'envoie des données")

  const [passwordVisible, setPasswordVisible] = useState(false);

  const eyeClick = () => {
    setPasswordVisible(!passwordVisible)
  }

  function CheckPassword(inputtxt) {
    return inputtxt.match(/(?=.*?[a-z])(?=.*?[0-9])(?=.*?[A-Z])(?=.*?[#?!@$%^&*-+_]).{6,20}/)
  }

  const validateData = () => {
    setError(false);
    if(!email || !firstName || !lastName){
      setError(true)
      setErrorMessage("Tous les champs du formulaire doivent être remplis")
      return false;
    }
    if(!entite || !service || !password || !confirmPassword){
      setError(true)
      setErrorMessage("Tous les champs du formulaire doivent être remplis")
      return false;
    }
    if(!CheckPassword(password)){
      setError(true)
      setErrorMessage(
          "le mot de passe doit contenir au moins un nombre," +
          " une majuscle, une minuscle ,un caractère spécial (#?!@$%^&*-+_) et doit avoir entre 6 et 20 caractères"
      )
      return false;
    }
    if(password !== confirmPassword){
      setError(true)
      setErrorMessage(
          "les mot de passe ne correspondent pas"
      )
      return false;
    }
    return true;
  }

  useEffect(() => {
    setEmail("");
    setFirstName("");
    setLastName("");
    setEntite("");
    setService("");
    setPassword("");
    setConfirmPassword("");
    setError(false);
  }, [props.show]);

  const onSubmit = e => {
    e.preventDefault();
    if(validateData()) {
      const user_data = {
        email: email,
        firstName: firstName,
        lastName: lastName,
        entite: entite,
        service: service,
        password: password,
      }
      fetch(base_url + 'user/newUser/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        body: JSON.stringify(user_data)
      })
      .then(res => {
        if (res.ok) {
          alert("l'utilisateur à bien été crée");
          props.setTrigger(props.trigger + 1)
          props.onHide()
          return res.json();
        }
        throw res;
      })
      .catch(error => {
        console.error("Error posting data user: ", error);
        setError(true)
      })
      }
    }

  return (
    <Modal
      {...props}
      size={"lg"}
      aria-labelledby={"contained-modal-title-vcenter"}
      centered
    >
      <Form onSubmit={onSubmit}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Enregister un nouvel utilisateur
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3" controlId="Email">
            <Form.Label>Email:</Form.Label>
            <Form.Control
                type="text"
                placeholder="Email"
                required
                onChange={e => setEmail(e.target.value)}
            />
            <Form.Text className="text-muted">
            </Form.Text>
          </Form.Group>
          <Form.Group className="mb-3" controlId="FirstName">
            <Form.Label>Prénom :</Form.Label>
            <Form.Control
                type="text"
                required
                placeholder="Prénom"
                onChange={e => setFirstName(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="LastName">
            <Form.Label>Nom :</Form.Label>
            <Form.Control
                type="text"
                required
                placeholder="Nom"
                onChange={e => setLastName(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="Entite">
            <Form.Label>Entite:</Form.Label>
            <SelectData
              value={entite}
              defaultValue="Entité"
              target="entite/"
              setValue={setEntite}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="Service">
            <Form.Label>Service:</Form.Label>
            <SelectData
              defaultValue="Service"
              target="service/"
              setValue={setService}
              value={service}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="Password">
            <Form.Label>Mot de passe :</Form.Label>
            <PasswordDiv>
              <Form.Control
                  type={passwordVisible? 'text': 'password'}
                  required
                  placeholder="password"
                  onChange={e => setPassword(e.target.value)}
              />
              <EyeIcon onClick={eyeClick} icon={"fas fa-eye"}/>
            </PasswordDiv>
          </Form.Group>
          <Form.Group className="mb-3" controlId="ConfirmPassword">
            <Form.Label>Comfirmer le mot de passe :</Form.Label>
            <PasswordDiv>
              <Form.Control
                  type={passwordVisible? 'text': 'password'}
                  required
                  placeholder="password"
                  onChange={e => setConfirmPassword(e.target.value)}
              />
              <EyeIcon onClick={eyeClick} icon={"fas fa-eye"}/>
            </PasswordDiv>
          </Form.Group>
          <Form.Control.Feedback style={{ display: error ? "block" : "none" }} type="invalid">
            {errorMessage}
          </Form.Control.Feedback>
        </Modal.Body>
        <Modal.Footer>
          <ButtonBar>
            <Button variant="primary" type="submit">
              Enregister
            </Button>
            <Button onClick={props.onHide} variant="secondary">
              Retour
            </Button>
          </ButtonBar>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

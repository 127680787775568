import styled from "styled-components";
import { MapContainer, TileLayer, Marker, Popup} from 'react-leaflet'
import L from 'leaflet'
import mapMarker from "../../../assets/pin.png"


const StyledMap = styled(MapContainer)`
  height: 700px;
  width: 1000px;
  margin: auto;
  margin-bottom: 100px;
`

var customIcon = L.icon({
  iconUrl: mapMarker,
  iconSize: [40, 40],
})

export default function Map() {

  return (
    <StyledMap center={[50.568965, 5.240542]} zoom={9} scrollWheelZoom={false}>
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <Marker position={[50.569946261709426, 5.242735114691933]}>
        <Popup>
          Bureau Prologe.
        </Popup>
      </Marker>
      <Marker position={[50.287854, 5.094380]} icon={customIcon}>
        <Popup>
          Ciney Roi Albert.
        </Popup>
      </Marker>
      <Marker position={[50.554177, 5.270297]}>
        <Popup>
          VILLERS-LE-BOUILLET Hochets.
        </Popup>
      </Marker>
      <Marker position={[50.217694, 5.330916]}>
        <Popup>
          Marche Avenue de France
        </Popup>
      </Marker>
    </StyledMap>
  )

}
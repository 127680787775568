import {useState} from "react";
import {useNavigate} from "react-router-dom";
import logo from '../../assets/Logo.png'
import picture from '../../assets/login_picture.jpg'
import Form from 'react-bootstrap/Form'
import {base_url} from "../../components/api_url/url";
import {
  BackGround,
  Warpper,
  FormWarpper,
  LogoWarpper,
  StyledForm,
  ImgWarpper,
  StyledImg,
  StyledButton,
  StyledPicture,
  ButtonWarpper,
} from './style.jsx'

function LoginPage({trigger, setTrigger}) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const navigate = useNavigate()

  const onSubmit = e => {
    e.preventDefault();

    const user = {
      email: email.toLowerCase(),
      password: password
    }
    fetch(base_url+'token/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(user)
    })
        .then(res => res.json())
        .then(data =>  {
          console.log("received data:")
          console.log(data)
          if (data.access) {
            localStorage.clear();
            localStorage.setItem('token', data.access)
            localStorage.setItem('refresh', data.refresh)
            localStorage.setItem('tokenTimeout', new Date())
            setTrigger(trigger + 1)
            console.log('Redirecting..')
            return(navigate("/"))
          } else {
            setEmail('');
            setPassword('');
            localStorage.clear();
            setErrorMessage("les identifiants fournis ne correspondent à aucun utilisateur connu");
          }
        })
  }

  return (
    <BackGround>
      <Warpper>
        <ImgWarpper>
          <StyledPicture src={picture}/>
        </ImgWarpper>
        <FormWarpper>
          <LogoWarpper>
            <StyledImg src={logo}/>
          </LogoWarpper>
        <StyledForm onSubmit={onSubmit}>
          <div className="form-group">
            <label htmlFor="exampleFormControlInput1">Email</label>
            <input
                name='email'
                type="email"
                value={email}
                className="form-control"
                placeholder="email"
                required
                onChange={e => setEmail(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="exampleFormControlInput1">Mot de Passe</label>
            <input
                name='password'
                type="password"
                value={password}
                className="form-control"
                placeholder="mot de passe"
                required
                onChange={e => setPassword(e.target.value)}
            />
          </div>
          <Form.Control.Feedback style={{display: errorMessage ? "block" : "none"}} type="invalid">
            {errorMessage}
          </Form.Control.Feedback>
          <ButtonWarpper>
            <StyledButton type="submit">Connexion</StyledButton>
          </ButtonWarpper>
        </StyledForm>
        </FormWarpper>
      </Warpper>
    </BackGround>
  )
}

export default LoginPage

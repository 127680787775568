import {styled} from "@mui/material/styles";

const Line = styled("div")`
  border-bottom: 1px solid #D6D6D6;
  margin: 10px 20px;
`

export default function Separator({color}){
  return(
      <Line/>
  )
}

// for test

export const base_url = "http://152.228.133.142/api/"

// for production

// export const base_url = " http://51.83.33.196/api/"

// // for develloppement

// export const base_url = " http://127.0.0.1:8000/api/"